import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import Panel from 'src/components/Panel/Panel';
import adminSelectors from 'src/redux/admin/admin-selectors';
import MemberCollectionRow from './MemberCollectionRow';

import styles from './MemberCollectionsTable.module.scss';

const MemberCollectionsTable = () => {
  const { t } = useTranslation();
  const collections = useSelector(adminSelectors.getCollections);
  return (
    <Panel className={cx(styles.panel)}>
      <div
        className={cx('row border-bottom ', styles.tableHeader)}
        data-testid="user-collection-table"
      >
        <div className={cx('col-4')}>
          {t('admin.member_collection_column_1')}
        </div>
        <div className={cx('col-4')}>
          {t('admin.member_collection_column_2')}
        </div>
        <div className={cx('col-4')}>
          {t('admin.member_collection_column_3')}
        </div>
      </div>
      {collections &&
        collections.map((collection, ind) => (
          <MemberCollectionRow key={ind} collection={collection} />
        ))}
    </Panel>
  );
};

export default MemberCollectionsTable;
