import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { updateCatalog } from 'src/redux/catalog/catalog-slice';

const UpdateCatalogDialog = () => {
  const { catalog, hideModal, dispatch } = useCatalogPageContext();
  const { t } = useTranslation();

  const [title, setTitle] = React.useState(catalog.title);
  const [titleError, setTitleError] = React.useState(false);
  const [description, setDescription] = React.useState(catalog.description);

  React.useEffect(() => {
    setTitle(catalog.title);
    setTitleError(false);
    setDescription(catalog.description);
  }, [catalog]);

  const handleSubmit = () => {
    if (title.length === 0) {
      setTitleError(true);
      return;
    }
    hideModal();
    dispatch(
      updateCatalog({
        ...catalog,
        title: title,
        description: description,
      })
    );
  };

  return (
    <>
      <DialogTitle>{t('insights.update_catalog')}</DialogTitle>
      <DialogContent data-testid="UpdateCatalogDialog">
        <DialogContentText>
          {catalog.community_id
            ? t('insights.update_catalog_title_mobile')
            : t('insights.update_catalog_title')}
        </DialogContentText>
        <TextField
          autoFocus
          disabled={catalog.community_id ? true : false}
          error={titleError}
          fullWidth
          label={t('common.title')}
          margin="dense"
          onChange={(event) => {
            setTitle(event.target.value);
            if (title.length !== 0) setTitleError(false);
          }}
          sx={{ mt: 3, mb: 2 }}
          value={title}
        />
        {catalog.community_id && (
          <DialogContentText>
            {t('insights.update_catalog_description')}
          </DialogContentText>
        )}
        <TextField
          fullWidth
          label={t('common.description')}
          margin="dense"
          minRows={3}
          multiline
          onChange={(event) => {
            setDescription(event.target.value);
          }}
          sx={{ mt: 3 }}
          value={description}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal}>{t('common.cancel')}</Button>
        <Button data-testid="UpdateCatalogSubmitButton" onClick={handleSubmit}>
          {t('common.submit')}
        </Button>
      </DialogActions>
    </>
  );
};

UpdateCatalogDialog.displayName = 'UpdateCatalogDialog';

export default connect()(UpdateCatalogDialog);
