import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from 'reactstrap';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import EditableTranscript from 'src/components/EditableTranscript/EditableTranscript';
import InteractiveTranscript from 'src/components/InteractiveTranscript/InteractiveTranscript';
import Panel from 'src/components/Panel/Panel';
import { NAVBAR_HEIGHT } from 'src/constants';
import { Conversation } from 'src/types/conversation';
import { getTranscript } from '../../../api/api';

import styles from '../ConversationRoute.module.scss';
import tabStyles from './TranscriptTab.module.scss';

const FONT_SIZES = [
  'medium',
  'large',
  'larger',
  'x-large',
  'xx-large',
  'xxx-large',
] as const;

interface TranscriptTabProps {
  isActive: boolean;
  autoScroll: boolean;
  conversation: Conversation;
  handlePlay: (seekTime?: number, endTime?: number) => void;
  handleScrollInterruption: () => void;
  showEditableTranscript: boolean;
  isStaff: boolean;
  initialSeekTime?: number;
}

const TranscriptTab = ({
  isActive,
  autoScroll,
  conversation,
  handlePlay,
  handleScrollInterruption,
  showEditableTranscript,
  isStaff,
  initialSeekTime,
}: TranscriptTabProps) => {
  const { t } = useTranslation();
  const [fontSizeIndex, setFontSizeIndex] = React.useState(0);
  const [editTranscript, setEditTranscript] = React.useState(false);
  // All refs need to be declared here for the scrolls to sync
  const previousEditState = React.useRef(false);
  const transcriptHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const viewTranscriptRef = React.useRef<HTMLDivElement | null>(null);
  const editableTranscriptRef = React.useRef<HTMLDivElement | null>(null);
  const [currentSeektimePosition, setCurrentSeekTimePosition] =
    React.useState<number>();
  const [isDownloading, setIsDownloading] = React.useState(false);

  React.useEffect(() => {
    // scroll down to the view when we are given a seek time
    // but don't do it when we are editing because it jumps the user out of what they were looking at
    if (initialSeekTime != null && !editTranscript) {
      // scroll to the Transcript, account for nav height + a lil padding
      if (transcriptHeaderRef.current) {
        window.scroll({
          top: transcriptHeaderRef.current.offsetTop - NAVBAR_HEIGHT - 5,
        });
      }
    }
  }, [initialSeekTime, editTranscript]);

  React.useEffect(() => {
    if (viewTranscriptRef.current && editableTranscriptRef.current) {
      if (currentSeektimePosition !== undefined) {
        if (currentSeektimePosition < 30) {
          // Scroll to top if near top
          viewTranscriptRef.current.scrollTop = 0;
          editableTranscriptRef.current.scrollTop = 0;
        } else {
          // Only execute if the previous edit state has changed.
          // Necessary since the scroll position is reset to 0 when the transcript div is hidden.
          // Using seektime rather than scroll position because the divs are of different height.
          const viewableElement = viewTranscriptRef.current.querySelector(
            `[data-transcript-word="true"][data-starttime="${currentSeektimePosition}"]`
          );
          const editableElement = editableTranscriptRef.current.querySelector(
            `[data-slate-leaf="true"][data-starttime="${currentSeektimePosition}"]`
          );

          viewTranscriptRef.current.scrollTop +=
            viewableElement?.getBoundingClientRect().top ?? 0;
          editableTranscriptRef.current.scrollTop +=
            (editableElement?.getBoundingClientRect().top ?? 0) - 73;
        }
      }
    }
  }, [
    viewTranscriptRef,
    editableTranscriptRef,
    editTranscript,
    currentSeektimePosition,
  ]);

  const toggleTranscriptMode = () => {
    if (viewTranscriptRef.current && editableTranscriptRef.current) {
      if (editTranscript === true) {
        // Moving from editable transcript to viewable transcript
        let seekTime: number | undefined;
        // Get seektime of first word seen on the screen
        editableTranscriptRef.current
          .querySelectorAll(`[data-slate-leaf="true"]`)
          .forEach((node) => {
            if (seekTime !== undefined) return;
            if (node.getBoundingClientRect().top > 73) {
              seekTime = parseFloat(node.getAttribute('data-starttime') ?? '0');
            }
          });
        setCurrentSeekTimePosition(seekTime);
      }
      if (editTranscript === false) {
        // Moving from viewable transcript to editable transcript
        let seekTime: number | undefined;
        // Get seektime of first word seen on the screen
        viewTranscriptRef.current
          .querySelectorAll(`[data-transcript-word="true"]`)
          .forEach((node) => {
            if (seekTime !== undefined) return;
            if (node.getBoundingClientRect().top > 0) {
              seekTime = parseFloat(node.getAttribute('data-starttime') ?? '0');
            }
          });
        setCurrentSeekTimePosition(seekTime);
      }
      setEditTranscript((self) => {
        previousEditState.current = self;
        return !self;
      });
    }
  };

  const handleDownload = () => {
    setIsDownloading(true);
    fetchTranscript();
  };

  const fetchTranscript = async () => {
    try {
      const response = await getTranscript(conversation.id);
      if (response.ok) {
        const contentType = response.headers.get('content-type');

        // decipher content-type
        if (contentType && contentType.includes('text/plain')) {
          const text = await response.text();

          downloadTranscript(text);
        } else if (contentType && contentType.includes('application/json')) {
          const json = await response.json();
          const errMsg = json.data.error;

          setIsDownloading(false);
          throw new Error(errMsg);
        } else {
          setIsDownloading(false);
          throw new Error('Unexpected content type');
        }
      } else {
        setIsDownloading(false);
        throw new Error(`Request Failed. Status: ${response.status}`);
      }
    } catch (error) {
      setIsDownloading(false);
      alert(error);
    }
  };

  const downloadTranscript = (response: string) => {
    const blob = new Blob([response], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.download = `transcript_${conversation.id}.txt`;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const handleIncrease = () => {
    if (fontSizeIndex < FONT_SIZES.length - 1) {
      setFontSizeIndex(fontSizeIndex + 1);
    }
  };
  const handleDecrease = () => {
    if (fontSizeIndex > 0) {
      setFontSizeIndex(fontSizeIndex - 1);
    }
  };
  const readyToEdit =
    conversation.transcript_edit_version != null &&
    conversation.speech_pipeline_status === 8;

  return (
    <>
      <div className={styles.headingContainer}>
        <div className="d-flex w-50">
          <h4 ref={transcriptHeaderRef}>{t('conversation.transcript')}</h4>
        </div>
        <div className="d-flex w-50 justify-content-end">
          {showEditableTranscript && (
            <Button
              color="primary"
              outline={!editTranscript}
              grayOutline={!editTranscript}
              whiteBg={!editTranscript}
              disabled={!readyToEdit}
              icon={['fas', 'pen']}
              className={`me-2 ${styles.transcriptButton}`}
              onClick={toggleTranscriptMode}
              title={readyToEdit ? undefined : t('transcript_editor.not_ready')}
              id="edit-transcript-button"
              data-testid="edit-transcript-button"
            >
              {editTranscript ? `${t('common.editing')}` : t('common.edit')}
            </Button>
          )}
          {isStaff && (
            <Button
              color="primary"
              grayOutline={true}
              whiteBg={true}
              icon={isDownloading ? ['far', 'loader'] : ['fas', 'download']}
              className={`me-2 ${styles.transcriptButton}`}
              onClick={handleDownload}
              data-testid="download-transcript-button"
              disabled={isDownloading}
            >
              {isDownloading ? 'Downloading' : 'Download'}
            </Button>
          )}
          <ButtonGroup>
            <span
              className={`rounded-start p-2 text-white ${styles.fontSizeSpan}`}
            >
              {t('conversation.font_size_btn')}
            </span>
            <Button
              color="primary"
              aria-label="Decrease font size"
              onClick={handleDecrease}
              icon={['far', 'minus']}
              disabled={fontSizeIndex === 0}
            />
            <Button
              color="primary"
              aria-label="Increase font size"
              onClick={handleIncrease}
              icon={['far', 'plus']}
              disabled={fontSizeIndex === FONT_SIZES.length - 1}
            />
          </ButtonGroup>
        </div>
      </div>
      <Panel
        p={null}
        className={tabStyles[`word-${FONT_SIZES[fontSizeIndex]}`]}
      >
        <div className={cx({ 'd-none': !editTranscript })}>
          {showEditableTranscript && readyToEdit && (
            <EditableTranscript
              snippets={conversation.snippets || []}
              ref={editableTranscriptRef}
              isStaff={isStaff}
            />
          )}
        </div>
        <InteractiveTranscript
          className={cx('ps-3 py-3 pe-0', { 'd-none': editTranscript })}
          innerStyle={{ maxHeight: '80vh' }}
          snippets={conversation.snippets || []}
          highlights={conversation.highlights || []}
          conversationId={conversation.id}
          onPlay={handlePlay}
          isActive={isActive}
          isMobile={!!conversation.forum}
          autoScroll={autoScroll}
          handleScrollInterruption={handleScrollInterruption}
          conversation={conversation}
          ref={viewTranscriptRef}
        />
      </Panel>
    </>
  );
};

export default TranscriptTab;
