import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Moment } from 'moment-timezone';

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import { saveTranscriptEdit } from 'src/redux/transcript-edit/transcript-edit-slice';
import { Annotation } from 'src/types/conversation';
import { SearchParams } from 'src/types/transcript';
import FindAndReplace from './FindAndReplace/FindAndReplace';
import RedactModal from './RedactModal/RedactModal';

import styles from './EditBar.module.scss';

//Amount of time (in seconds) to wait to refresh the time since last save
const REFRESH_INTERVAL = 1;

interface SaveMessageProps {
  saving: boolean;
  saveTime: Moment | undefined;
  error: Error | undefined;
}
const SaveMessage = ({ saving, saveTime, error }: SaveMessageProps) => {
  const [timeSinceSave, setTimeSinceSave] = React.useState<string>();
  const { t } = useTranslation();
  const intervalRef = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    // Needed to refresh the timeSinceSave
    if (saveTime) {
      setTimeSinceSave(saveTime.fromNow());
      intervalRef.current = setInterval(() => {
        setTimeSinceSave(saveTime.fromNow());
      }, REFRESH_INTERVAL * 1000);
    }
    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, [saveTime]);

  if (saving) {
    return <LoadingSpinner active size="md" />;
  }
  if (error) {
    return (
      <div
        data-testid="edit-error"
        className="text-danger d-flex align-items-center"
      >
        <div>
          <FontAwesomeIcon
            icon={['fas', 'exclamation-circle']}
            className="me-2"
          />
        </div>
        {t('transcript_editor.save_error')}
      </div>
    );
  }
  if (saveTime) {
    return (
      <div data-testid="last-save">
        {t('transcript_editor.save_time', { relative_time: timeSinceSave })}
      </div>
    );
  }

  return <FontAwesomeIcon icon={['fas', 'pen']} />;
};

interface EditBarProps {
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  saveTime?: Moment;
  isSaving: boolean;
  searchParams: SearchParams;
  setSearchParams: (s: SearchParams) => void;
  onNextSearch: () => void;
  onPreviousSearch: () => void;
  onReplace: (text: string, all: boolean) => void;
  handleRedactions: (
    trimTimes: Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>
  ) => void;
  searchIndex: number;
  totalSearchResults: number;
  error: Error | undefined;
  isStaff: boolean;
  redactionParams: {
    annotation?: Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>;
    wordsToRedact?: string;
  };
}

const EditBar = ({
  undo,
  redo,
  canUndo,
  canRedo,
  saveTime,
  isSaving,
  searchParams,
  setSearchParams,
  onNextSearch,
  onPreviousSearch,
  onReplace,
  handleRedactions,
  searchIndex,
  totalSearchResults,
  error,
  isStaff,
  redactionParams,
}: EditBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showFindAndReplace, setShowFindAndReplace] = React.useState(false);

  const [confirmationModal, setConfirmationModal] =
    React.useState<boolean>(false);

  const handleCloseSearch = () => {
    setShowFindAndReplace(false);
    setSearchParams({ ...searchParams, isSearching: false });
  };

  const handleOpenSearch = () => {
    setShowFindAndReplace(true);
    setSearchParams({ ...searchParams, isSearching: true });
  };

  const onReset = React.useCallback(() => {
    dispatch(saveTranscriptEdit({ reset: true }));
    setConfirmationModal(false);
  }, [dispatch]);
  return (
    <div className="border-bottom px-2 py-3 d-flex justify-content-between">
      <div
        className={cx({
          'col-6': !showFindAndReplace,
          'col-4': showFindAndReplace,
        })}
      >
        <SaveMessage saving={isSaving} saveTime={saveTime} error={error} />
      </div>

      <div
        className={cx('d-flex justify-content-end', {
          'col-6 align-items-center': !showFindAndReplace,
          'col-8': showFindAndReplace,
        })}
      >
        <div className="pe-2 ">
          <Button
            onClick={(e) => {
              undo();
              // Undo focus styling
              (e.currentTarget as HTMLButtonElement).blur();
            }}
            icon={['far', 'undo']}
            color="plain"
            size="lg"
            disabled={!canUndo}
            className={styles.icon}
            aria-label="undo"
          />
          <Button
            onClick={(e) => {
              redo();
              // Undo focus styling
              (e.currentTarget as HTMLButtonElement).blur();
            }}
            icon={['far', 'redo']}
            color="plain"
            size="lg"
            id={'redo-icon'}
            disabled={!canRedo}
            className={styles.icon}
            aria-label="redo"
          />
        </div>
        <div className={cx('me-3', { 'flex-grow-1': showFindAndReplace })}>
          {showFindAndReplace ? (
            <FindAndReplace
              className={cx('py-3 ps-3', styles.findAndReplace)}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              onClose={handleCloseSearch}
              onNextSearch={onNextSearch}
              onPreviousSearch={onPreviousSearch}
              onReplace={onReplace}
              searchIndex={searchIndex}
              totalSearchResults={totalSearchResults}
            />
          ) : (
            <Button
              onClick={handleOpenSearch}
              data-testid="find-and-replace-btn"
              data-ignore-click={true}
            >
              {t('transcript_editor.find')}
            </Button>
          )}
        </div>
        {isStaff && (
          <div>
            <Button
              onClick={() => setConfirmationModal(true)}
              data-testid="transcript-reset-button"
            >
              {t('transcript_editor.reset')}
            </Button>
            <ConfirmationModal
              isLoading={false}
              isOpen={confirmationModal}
              handleCloseModal={() => setConfirmationModal(false)}
              text={{
                question: t('transcript_editor.reset_modal_header'),
                description: t('transcript_editor.reset_modal_description'),
                confirm: t('transcript_editor.reset'),
              }}
              confirm={onReset}
            />
            <RedactModal
              onRedact={handleRedactions}
              redactionParams={redactionParams}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditBar;
