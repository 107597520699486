import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { StringParam, useQueryParams } from 'use-query-params';

import Panel from 'src/components/Panel/Panel';
import { User } from 'src/types/auth';
import { CommunityMember } from 'src/types/forum';
import OrganizationRoleTooltip from '../OrganizationMembers/Tooltips/OrganizationRoleTooltip';
import MembersRow from './MemberRow';

import styles from './MemberTable.module.scss';

interface Props {
  members: User[] | CommunityMember[];
}

const MembersTable = ({ members }: Props) => {
  const { t } = useTranslation();

  const [query] = useQueryParams({
    tab: StringParam,
  });
  const { tab: activeTab } = query;

  return (
    <Panel className={cx(styles.panel)} data-testid="team-mgmt-table">
      <div className={cx('row w-100 border-bottom', styles.tableHeader)}>
        {/* A row has a maximum of 12 columns */}
        <div className={cx('p-0 ps-4 fwnormal col-4')}>
          {t('admin.tab_team_member_list_column_1')}
        </div>
        <div className={cx('p-0 fwnormal col-2')} style={{ display: 'flex' }}>
          {activeTab === 'organization_members'
            ? t('admin.tab_team_member_list_column_2')
            : t('admin.tab_community_member_list_column_2')}
          <OrganizationRoleTooltip
            width="300px"
            communityTable={activeTab === 'community_members'}
          />
        </div>
        <div className={cx('p-0 fwnormal col-5')}>
          {t('admin.tab_team_member_list_column_3')}
        </div>
        <div className={cx('p-0 fwnormal col-1')}></div>
      </div>
      {members?.map((member, ind) => (
        <MembersRow
          members={members}
          member={member}
          key={member.id}
          isFirst={ind === 0}
        />
      ))}
    </Panel>
  );
};

export default MembersTable;
