import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { getUsers, setCurrentUser } from 'src/redux/admin/admin-slice';
import RemoveMemberButton from '../RemoveMemberButton/RemoveMemberButton';
import CollectionRoleTooltip from '../Tooltips/CollectionRoleTooltip';
import MemberCollectionsTable from './MemberCollectionsTable/MemberCollectionsTable';
import MemberDetails from './MemberDetails/MemberDetails';

const ViewMember = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });
  const { userId } = query;
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const collections = useSelector(adminSelectors.getCollections);
  const members = useSelector(adminSelectors.getUsers);
  const currentMember = useSelector(adminSelectors.getCurrentUser);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const userCollectionsLength = React.useMemo(
    () =>
      currentMember?.roles?.filter(
        (role) =>
          role?.collection_id &&
          collections.map(({ id }) => id).includes(role.collection_id)
      ).length,
    [currentMember, collections]
  );

  React.useEffect(() => {
    if (!userId) {
      //return to the Team List
      setQuery({ page: undefined, userId: undefined });
    } else if (!members.length) {
      // get fresh members
      dispatch(getUsers({ userType: 'members', organizationId }));
    } else if (!currentMember || currentMember.id !== userId) {
      dispatch(setCurrentUser({ id: userId }));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [userId, members, currentMember, dispatch, organizationId, setQuery]);

  return (
    <div>
      <Button
        color={'link'}
        onClick={() =>
          setQuery({ userId: undefined, page: undefined }, 'pushIn')
        }
        icon={['far', 'arrow-left']}
        content={t('admin.go_to_team')}
      />
      {isLoading ? (
        <div className="w-100 text-center">
          <LoadingSpinner thick size="lg" active theme="dark" />
        </div>
      ) : !currentMember ? (
        <div>{t('admin.tab_team_view_member_not_exist')}</div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex">
                <span>
                  {t('admin.member_helper_1', {
                    name: currentMember?.first_name ?? currentMember?.email,
                  })}
                </span>
                <CollectionRoleTooltip width="300px" />
              </div>
              <span>
                {t('admin.member_helper_2', {
                  name: currentMember?.first_name ?? currentMember?.email,
                  count: userCollectionsLength ?? 0,
                })}
              </span>
            </div>
            <RemoveMemberButton member={currentMember} />
          </div>
          <MemberDetails member={currentMember} />
          <MemberCollectionsTable />
        </>
      )}
    </div>
  );
};

export default ViewMember;
