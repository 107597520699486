import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import memoize from 'memoizee';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import ClosableTab from 'src/components/ClosableTab/ClosableTab';
import ConversationHighlights from 'src/components/ConversationHighlights/ConversationHighlights';
import ConversationSearchResults from 'src/components/ConversationSearchResults/ConversationSearchResults';
import ConversationSpeakerExcerpts from 'src/components/ConversationSpeakerExcerpts/ConversationSpeakerExcerpts';
import ConversationTimeline from 'src/components/ConversationTimeline/ConversationTimeline';
import PlaybackControls from 'src/components/ConversationTimeline/PlaybackControls/PlaybackControls';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import Layout from 'src/components/Layout/Layout';
import SearchInput from 'src/components/SearchInput/SearchInput';
import TopicsDropdown from 'src/components/TopicsDropdown/TopicsDropdown';
import GlobalAudioContext from 'src/contexts/GlobalAudioContext';
import { useAnalyticsContext } from 'src/Providers/AnalyticsProvider';
import authSelectors from 'src/redux/auth/auth-selectors';
import conversationSelectors from 'src/redux/conversation/conversation-selectors';
import {
  editConversation,
  editConversationDraftState,
  loadConversation,
} from 'src/redux/conversation/conversation-slice';
import entitiesSelectors from 'src/redux/entities/entities-selectors';
import { StoreState } from 'src/redux/store';
import { Action, Category, Name } from 'src/types/analytics';
import { User } from 'src/types/auth';
import {
  Conversation,
  ConversationPrivacyLevel,
  Snippet,
  TermTimings,
  Topic,
} from 'src/types/conversation';
import { matchSnippets, termTimings } from 'src/util/snippets';
import { redirectUserToConversations } from 'src/util/urls';
import {
  getConversationTranscriptPermission,
  getDraftConversationPermission,
  getStaffFeaturesPermission,
  hasAnyPermission,
  hasPermission,
  isAnonymous,
} from 'src/util/user';
import ConversationInfo from './ConversationInfo/ConversationInfo';
import DraftBanner from './DraftBanner/DraftBanner';
import StaffFeatures from './StaffFeatures/StaffFeatures';
import TranscriptTab from './Tabs/TranscriptTab';

import styles from './ConversationRoute.module.scss';

type BaseProps = RouteComponentProps<{ conversationId: string }>;

interface StateProps {
  conversation: Conversation | undefined;
  isLoading: boolean;
  error: Error | undefined;
  user: User;
  isSavingEditConversationDraftState: boolean;
  isSavedEditConversationDraftState: boolean;
  editConversationDraftStateError: Error | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState, props: BaseProps): StateProps => {
  // @ts-ignore haven't figured out how to type this to accept the second parameter
  const conversation = entitiesSelectors.getConversationDetails(state, props);

  return {
    conversation,
    isLoading: conversationSelectors.isLoading(state),
    error: conversationSelectors.getError(state),
    user: authSelectors.getUser(state),
    isSavingEditConversationDraftState:
      conversationSelectors.isSavingEditConversationDraftState(state),
    isSavedEditConversationDraftState:
      conversationSelectors.isSavedEditConversationDraftState(state),
    editConversationDraftStateError:
      conversationSelectors.getErrorEditConversationDraftState(state),
  };
};

const getSearchResults = memoize(matchSnippets);
const getToggledTermSnippets = memoize(matchSnippets);
const getSearchTermTimings = memoize(termTimings);
const getSpeakerSnippets = memoize(
  (snippets: Snippet[], speakerId: string | number) => {
    return snippets.filter((snippet) => snippet.speaker_id === speakerId);
  }
);

/**
 * Detailed conversation page
 */
export const ConversationRoute: React.FunctionComponent<Props> = ({
  location,
  match,
  dispatch,
  conversation,
  error,
  isLoading,
  user,
  isSavingEditConversationDraftState,
  isSavedEditConversationDraftState,
  editConversationDraftStateError,
}) => {
  const { t } = useTranslation();
  const conversationId = +match.params.conversationId;

  React.useEffect(() => {
    // if the user is anonymous and we get an error that no conversation was found,
    // redirect to the sign in page
    if (isAnonymous(user) && error) {
      let to = '/';
      if (location && location.pathname) {
        to = location.pathname;
      }
      // use assign() for easier test stubbing https://github.com/jsdom/jsdom/issues/2112
      window.location.assign(`/login/?next${redirectUserToConversations(to)}`);
    }
  }, [user, error, location]);

  /** Define URL query parameters */
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    speaker: StringParam,
    term: StringParam,
    q: StringParam,
    t: NumberParam,
    topic: StringParam,
  });
  let {
    // rename query params to readable variable names
    tab: activeTab,
  } = query;
  const {
    speaker: toggledSpeakerId,
    term: toggledTermString,
    q: searchQuery,
    t: initialSeekTime,
    topic: activeTopicName,
  } = query;

  const {
    src,
    seek,
    play,
    pause,
    changeSound,
    stop,
    isPlaying,
    isLoading: isAudioLoading,
    audioError,
    conditionalSeek,
  } = React.useContext(GlobalAudioContext);
  const isConversationAudioLoading =
    isAudioLoading && conversation != null && src === conversation.audio_url;

  // if transcript should scroll with audio or not
  const [autoScroll, setAutoScroll] = React.useState(true);
  const [allowScrollInterrupt, setAllowScrollInterrupt] = React.useState(false);

  // wrap in useCallback to prevent rerender which causes transcript to
  // scroll back to the top when a highlight is created
  const handleScrollInterruption = React.useCallback(() => {
    if (allowScrollInterrupt) {
      setAutoScroll(false);
    }
  }, [allowScrollInterrupt]);

  React.useEffect(() => {
    if (isPlaying) {
      setAllowScrollInterrupt(true);
    }
  }, [isPlaying]);

  React.useEffect(() => {
    // View in Transcript can set the 't' param
    // if the user has disabled autoscroll, reenable it so we can scroll properly
    setAutoScroll(true);
  }, [initialSeekTime]);

  // set active tab to highlights if we have them and no tab has been explicitly set, otherwise transcript
  if (activeTab == null) {
    // if we have highlights, default to showing them, unless we have a seek parameter
    if (
      conversation != null &&
      conversation.highlights &&
      conversation.highlights.length &&
      initialSeekTime == null
    ) {
      activeTab = 'highlights';
    } else {
      activeTab = 'transcript';
    }
  }

  // load conversation data when the ID changes / on mount
  React.useEffect(() => {
    dispatch(loadConversation(conversationId));

    return () => {
      // ensure audio stops playing if we leave this page or change conversation
      stop();
    };
  }, [conversationId, dispatch, stop]);

  // if the audio URL has changed, update the sound file
  React.useEffect(() => {
    if (conversation) {
      const { audio_url } = conversation;
      changeSound(audio_url, conversation);

      // run if conversation audio_url changes (e.g. the conversation data has loaded
      // and we now have an audio URL). should basically happen only at mount.
      // need to seek after the audio_url is set, so the src of our audio is proper
      if (initialSeekTime != null) {
        seek(initialSeekTime);
      }
    }
  }, [changeSound, conversation, conversationId, initialSeekTime, seek]);

  // get the top term timings from the conversation meta
  const toggledTerm =
    conversation &&
    conversation.topic_terms &&
    conversation.topic_terms.find((term) => term.term === toggledTermString);

  const handleToggleSpeaker = React.useCallback(
    (speakerId: string | undefined) => {
      if (toggledSpeakerId !== speakerId && speakerId) {
        setQuery(
          {
            speaker: speakerId,
            q: undefined,
            term: undefined,
            tab: 'speaker-results',
          },
          'pushIn'
        );
      } else {
        setQuery(
          {
            speaker: undefined,
            tab: activeTab === 'speaker-results' ? undefined : activeTab,
          },
          'pushIn'
        );
      }
    },
    [toggledSpeakerId, activeTab, setQuery]
  );

  const handleToggleTerm = React.useCallback(
    (term: string | undefined) => {
      if (toggledTermString !== term && term) {
        setQuery(
          { term, q: undefined, speaker: undefined, tab: 'term-results' },
          'pushIn'
        );
      } else {
        setQuery(
          {
            term: undefined,
            tab: activeTab === 'term-results' ? undefined : activeTab,
          },
          'pushIn'
        );
      }
    },
    [toggledTermString, activeTab, setQuery]
  );

  const handleSearch = React.useCallback(
    (q: string | undefined) => {
      // if we are clearing the search, change the tab
      const resetTab = activeTab === 'search-results' ? undefined : activeTab;
      setQuery(
        {
          q,
          term: undefined,
          speaker: undefined,
          tab: q ? 'search-results' : resetTab,
        },
        'pushIn'
      );
    },
    [activeTab, setQuery]
  );

  const handleCloseTab = React.useCallback(
    (tabKey: string) => {
      // reset the search term, toggled term, or speaker, and reset to the default or active tab
      const tabToGoTo = activeTab === tabKey ? '' : activeTab;
      if (tabKey === 'search-results') {
        setQuery({ q: '', tab: tabToGoTo }, 'pushIn');
      } else if (tabKey === 'term-results') {
        setQuery({ term: undefined, tab: tabToGoTo }, 'pushIn');
      } else if (tabKey === 'speaker-results') {
        setQuery({ speaker: undefined, tab: tabToGoTo }, 'pushIn');
      }
    },
    [activeTab, setQuery]
  );

  const handleViewHighlights = React.useCallback(() => {
    setQuery({ tab: 'highlights' });
  }, [setQuery]);

  const handleViewInTranscript = React.useCallback(
    (targetSeekTimeMin: number, targetSeekTimeMax?: number) => {
      const withinLimit = 10; // if within 10s of seekTime, don't seek.
      const minTime = targetSeekTimeMin - withinLimit;
      const maxTime =
        (targetSeekTimeMax == null ? targetSeekTimeMin : targetSeekTimeMax) +
        withinLimit;

      setQuery({ tab: 'transcript', t: targetSeekTimeMin });

      // ensure we are using the right sound file (not a x-poll conv)
      changeSound(conversation && conversation.audio_url, conversation);
      conditionalSeek(minTime, maxTime, targetSeekTimeMin);
    },
    [conditionalSeek, setQuery, changeSound, conversation]
  );
  const { analyticsEvent } = useAnalyticsContext();

  const handlePlay = React.useCallback(
    (seekTime: number | undefined, endTime?: number) => {
      if (!conversation) {
        return;
      }

      // ensure we are using the right sound file (not a x-poll conv)
      changeSound(conversation && conversation.audio_url, conversation);
      play(seekTime, endTime);

      analyticsEvent({
        category: Category.Conversation,
        action: Action.Play,
        name: Name.ConversationRoute,
      });
    },
    [conversation, changeSound, play]
  );

  const handlePause = React.useCallback(() => {
    // ensure we are using the right sound file (not a x-poll conv)
    changeSound(conversation && conversation.audio_url, conversation);
    pause();
    if (conversation != null) {
      analyticsEvent({
        category: Category.Conversation,
        action: Action.Pause,
        name: Name.ConversationRoute,
      });
    }
  }, [conversation, pause, changeSound]);

  const handleSeek = React.useCallback(
    (seekTime: number, play?: boolean) => {
      // ensure we are using the right sound file (not a x-poll conv)
      changeSound(conversation && conversation.audio_url, conversation);
      seek(seekTime, play);

      if (conversation != null) {
        // throttle what we send to analytics
        analyticsEvent({
          category: Category.Conversation,
          action: Action.Seek,
          name: Name.ConversationRoute,
        });
      }
    },
    [conversation, seek, changeSound]
  );

  const activeTopic = conversation?.topics?.find(
    (t) => t.name === activeTopicName
  );

  // the top terms to be used in the timeline
  // can either be top terms from the entire conversation or the top terms related to a topic
  let activeTopTerms: TermTimings[] = [];
  if (conversation && conversation.topic_terms) {
    if (activeTopic != null) {
      activeTopTerms = conversation.topic_terms.filter(
        (t) => t.topics.indexOf(activeTopic.id) >= 0
      );
    } else {
      activeTopTerms = conversation.topic_terms;
    }
  }

  const handleTopicSelection = React.useCallback(
    (topic: Topic) => {
      const topicCode = topic.name;
      setQuery({ topic: topicCode }, 'pushIn');
    },
    [setQuery]
  );

  const handleClearTopic = React.useCallback(() => {
    setQuery({ topic: undefined }, 'pushIn');
  }, [setQuery]);

  const duration = conversation?.duration || 1;

  // for toggled speaker
  let speakerSnippets;
  let toggledSpeakerName;
  if (conversation && toggledSpeakerId) {
    speakerSnippets = getSpeakerSnippets(
      conversation.snippets || [],
      toggledSpeakerId
    );
    toggledSpeakerName = speakerSnippets.length
      ? speakerSnippets[0].speaker_name
      : 'Unknown Speaker';
  }

  const handleChangeDraftStatus = (is_draft: boolean) => {
    if (conversation) {
      dispatch(
        editConversationDraftState({
          conversationId: conversation.id,
          is_draft,
        })
      );
    }
  };

  const canChangeDraftStatus = !!(
    conversation &&
    hasPermission(
      user,
      getDraftConversationPermission(
        'update',
        conversation.collection?.id || conversation.forum?.id
      )
    )
  );

  // permissions related to tab visibility
  const userIsStaff = hasAnyPermission(user, [
    getStaffFeaturesPermission('read'),
  ]);
  const canEditTranscript =
    conversation && conversation.forum
      ? false
      : !!(
          conversation &&
          hasPermission(
            user,
            getConversationTranscriptPermission(
              'update',
              conversation.collection?.id || conversation.forum?.id
            )
          )
        );
  const canSeeAdminTab = userIsStaff || canChangeDraftStatus;
  const canSeeSpeakerViewTab =
    toggledSpeakerId && toggledSpeakerName && speakerSnippets;

  // data for each tab, based on https://reach.tech/tabs/#data-driven-tabs
  // a bit more complicated so we can conditionally render and have closable tabs
  const allTabData = conversation
    ? [
        {
          label: conversation.highlights && (
            <>
              {`${t('highlights.header')} `}
              <span className="fwnormal small">
                ({conversation.highlights.length})
              </span>
            </>
          ),
          key: 'highlights',
          include: true,
          content: (
            <>
              <h4>{t('highlights.header')}</h4>
              <ConversationHighlights
                conversation={conversation}
                onViewInTranscript={handleViewInTranscript}
              />
            </>
          ),
          closable: false,
        },
        {
          label: t('conversation.transcript'),
          include: true,
          key: 'transcript',
          content: (
            <TranscriptTab
              isActive={activeTab === 'transcript'}
              autoScroll={autoScroll}
              conversation={conversation}
              handlePlay={handlePlay}
              handleScrollInterruption={handleScrollInterruption}
              showEditableTranscript={canEditTranscript}
              initialSeekTime={initialSeekTime}
              isStaff={userIsStaff}
            />
          ),
          closable: false,
        },
        {
          label: 'Admin',
          include: canSeeAdminTab,
          key: 'admin',
          content: canSeeAdminTab && (
            <StaffFeatures
              conversation={conversation}
              onChangeDraftStatus={handleChangeDraftStatus}
              isSavedDraftState={isSavedEditConversationDraftState}
              isSavingDraftState={isSavingEditConversationDraftState}
              errorDraftState={editConversationDraftStateError}
              canChangeDraftStatus={canChangeDraftStatus}
              userIsStaff={userIsStaff}
              className="col-lg-8"
            />
          ),
          closable: false,
        },
        {
          label: searchQuery,
          include: searchQuery,
          key: 'search-results',
          content: searchQuery && (
            <>
              <h4>
                {t('conversation.transcript_excerpt_keyword_title', {
                  keyword: searchQuery,
                })}
              </h4>
              <ConversationSearchResults
                conversation={conversation}
                snippets={getSearchResults(
                  conversation.snippets || [],
                  searchQuery
                )}
                // safe to cast to string because searchQuery is in the include clause
                searchQuery={searchQuery}
                onPlay={handlePlay}
                onPause={handlePause}
                onViewInTranscript={handleViewInTranscript}
              />
            </>
          ),
          closable: true,
        },
        {
          label: toggledTerm && toggledTerm.term,
          include: toggledTerm,
          key: 'term-results',
          content: toggledTerm && (
            <>
              <h4>
                {t('conversation.transcript_excerpt_keyword_title', {
                  keyword: toggledTerm.term,
                })}
              </h4>
              <ConversationSearchResults
                conversation={conversation}
                snippets={getToggledTermSnippets(
                  conversation.snippets || [],
                  toggledTerm.term
                )}
                searchQuery={toggledTerm.term}
                onPlay={handlePlay}
                onPause={handlePause}
                onViewInTranscript={handleViewInTranscript}
              />
            </>
          ),
          closable: true,
        },
        {
          label: toggledSpeakerName,
          include: canSeeSpeakerViewTab,
          key: 'speaker-results',
          content: canSeeSpeakerViewTab && (
            <>
              <h4>
                {t('conversation.transcript_excerpt_speaker_title', {
                  name: toggledSpeakerName,
                })}
              </h4>
              {/* force types because canSeeSpeakerViewTab ensures not undefined */}
              <ConversationSpeakerExcerpts
                conversation={conversation}
                snippets={speakerSnippets as Snippet[]}
                onPlay={handlePlay}
                onPause={handlePause}
                speakerName={toggledSpeakerName as string}
                onViewInTranscript={handleViewInTranscript}
              />
            </>
          ),
          closable: true,
        },
      ]
    : [];

  const tabData = allTabData.filter((d) => d.include);
  const currentTabIndex = tabData.map((d) => d.key).indexOf(activeTab);

  const handleChangeTab = React.useCallback(
    (tabIndex: number) => {
      if (currentTabIndex !== tabIndex) {
        setQuery({ tab: tabData[tabIndex].key }, 'pushIn');
      }
    },
    [currentTabIndex, tabData, setQuery]
  );

  const handleChangeConversationPrivacy = (
    privacyLevel: ConversationPrivacyLevel
  ) => {
    if (conversation) {
      dispatch(
        editConversation({
          conversationId: conversation.id,
          changes: {
            privacy_level: privacyLevel,
          },
        })
      );
    }
  };

  return (
    <Layout
      className="ConversationRoute"
      title={conversation && conversation.title}
      closableAudioPlayer={false}
      banner={
        canChangeDraftStatus && (
          <DraftBanner
            isSaving={isSavingEditConversationDraftState}
            saveError={editConversationDraftStateError}
            isSaved={isSavedEditConversationDraftState}
            conversation={conversation}
            onChangeDraftStatus={handleChangeDraftStatus}
          />
        )
      }
    >
      <div data-testid="conversation-info">
        <div className="container mt-4 mb-4 pb-4 ps-0 border-bottom">
          <ConversationInfo
            conversation={conversation}
            isLoading={isLoading}
            error={error}
            user={user}
            onChangeConversationPrivacy={handleChangeConversationPrivacy}
          />
        </div>
      </div>
      <div className="clearfix container mb-4">
        {conversation?.topics && (
          <TopicsDropdown
            className="float-end me-n4"
            onClear={handleClearTopic}
            onTopicSelection={handleTopicSelection}
            topics={conversation.topics}
            isLoadingTopics={isLoading}
            activeTopic={activeTopic ? activeTopic : null}
            dropdownText={t('conversation.topic_dropdown')}
          />
        )}
      </div>
      <div className="container-fluid">
        <div>
          {conversation && (
            <AutoSizer defaultWidth={400} disableHeight>
              {({ width }) => (
                <ConversationTimeline
                  width={width}
                  conversation={conversation}
                  topTerms={activeTopTerms}
                  toggledSpeakerId={toggledSpeakerId}
                  onToggleSpeaker={handleToggleSpeaker}
                  toggledTerm={toggledTerm}
                  onToggleTerm={handleToggleTerm}
                  searchTermTimings={getSearchTermTimings(
                    conversation.snippets || [],
                    searchQuery
                  )}
                  onViewHighlights={handleViewHighlights}
                  duration={duration}
                  onPlay={handlePlay}
                  onSeek={handleSeek}
                  isLoadingTopTerms={false}
                />
              )}
            </AutoSizer>
          )}
        </div>
      </div>
      {conversation && (
        <div className="container">
          <div className="mb-5">
            <PlaybackControls
              isPlaying={isPlaying}
              audioUrl={conversation && conversation.audio_url}
              isLoading={isLoading || isConversationAudioLoading}
              onPlay={handlePlay}
              error={audioError}
              onPause={handlePause}
              duration={duration}
            />
          </div>
        </div>
      )}
      {conversation && (
        <div className="container mb-5">
          <div className={styles.searchContainer}>
            <SearchInput
              initialSearchQuery={searchQuery || ''}
              onSearch={handleSearch}
              shape="rect"
              placeholder={t('conversation.conversation_search')}
            />
          </div>
          <Tabs index={currentTabIndex} onChange={handleChangeTab}>
            <TabList>
              {tabData.map((tab, index) => (
                <Tab
                  key={index}
                  closable={tab.closable}
                  onClose={() => handleCloseTab(tab.key)}
                  as={ClosableTab}
                  data-testid={`${tab.key}-tab`}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {tabData.map((tab, index) => (
                <TabPanel key={index} data-testid={`${tab.key}-tab-panel`}>
                  {tab.content}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </div>
      )}
      {!conversation && isLoading && (
        <div className="position-relative" style={{ minHeight: '80vh' }}>
          <LoadingOverlay bgColor="transparent" active />
        </div>
      )}
    </Layout>
  );
};

// use connect() to get redux to connect to the component
export default connect(mapStateToProps)(React.memo(ConversationRoute));
