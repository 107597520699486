import { StringParam, useQueryParams } from 'use-query-params';

type PageName = 'add' | 'view';

const Communities = () => {
  const [query] = useQueryParams({
    page: StringParam,
  });
  const { page: activePage } = query;

  switch (activePage as PageName) {
    case 'add':
      return <div>Add Page</div>;
    case 'view':
      return <div>View Page</div>;
    default:
      // defaults to the main page
      return (
        <div>
          <div>Communities Scaffold</div>
        </div>
      );
  }
};

export default Communities;
