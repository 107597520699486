import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import { LoadingMode } from 'src/redux/catalog/catalog-slice';
import {
  setFiltersVisibility,
  setVisibility,
} from 'src/redux/catalog-filters/catalog-filters-slice';
import { StoreState } from 'src/redux/store';
import { AbstractCode, VisibilityCategory } from 'src/types/insights';

interface BaseProps {
  anchor: Element | null;
  closeMenu: () => void;
  label: string;
  openDialog: (key: string) => () => void;
  selectedCode: AbstractCode;
  visibilityCategory: VisibilityCategory;
  allowFiltering: boolean;
}

interface StateProps {
  loadingMode: LoadingMode;
}

type Props = BaseProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  loadingMode: catalogSelectors.getLoadingMode(state),
});

//TODO: delete me after insights restyle feature flag
const CodeAccordionMenu: React.FunctionComponent<Props> = (props: Props) => {
  const {
    allowFiltering,
    anchor,
    closeMenu,
    dispatch,
    label,
    loadingMode,
    openDialog,
    selectedCode,
    visibilityCategory,
  } = props;
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={anchor}
      onClose={() => {
        closeMenu();
      }}
      open={Boolean(anchor)}
    >
      <MenuList>
        {selectedCode.id !== -2 && [
          <MenuItem key="update-code-menu-item" onClick={openDialog('update')}>
            <ListItemIcon>
              <MoveDownIcon />
            </ListItemIcon>
            <ListItemText>
              {t('insights.edit_value', { value: label })}
            </ListItemText>
          </MenuItem>,
        ]}
        {allowFiltering && [
          <Divider />,
          <MenuItem
            onClick={() => {
              closeMenu();
              if (selectedCode === undefined) {
                return;
              }
              dispatch(
                loadingMode === 'fast'
                  ? setFiltersVisibility({
                      category: visibilityCategory,
                      id: selectedCode.id,
                      visible: true,
                    })
                  : setVisibility({
                      category: visibilityCategory,
                      id: selectedCode.id,
                      visible: true,
                    })
              );
            }}
          >
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText>{t('insights.show_coded_entries')}</ListItemText>
          </MenuItem>,
          <MenuItem
            onClick={() => {
              closeMenu();
              if (selectedCode === undefined) {
                return;
              }
              dispatch(
                setVisibility({
                  category: visibilityCategory,
                  id: selectedCode.id,
                  visible: false,
                })
              );
            }}
          >
            <ListItemIcon>
              <VisibilityOffIcon />
            </ListItemIcon>
            <ListItemText>{t('insights.hide_coded_entries')}</ListItemText>
          </MenuItem>,
        ]}
        {selectedCode.id !== -2 && [
          <Divider />,
          <MenuItem key="delete-code-menu-item" onClick={openDialog('delete')}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>
              {t('insights.delete_value', { value: label })}
            </ListItemText>
          </MenuItem>,
        ]}
      </MenuList>
    </Menu>
  );
};

CodeAccordionMenu.displayName = 'CodeAccordionMenu';

export default connect(mapStateToProps)(CodeAccordionMenu);
