import * as React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import OrganizationFavicon from 'src/components/OrganizationFavicon/OrganizationFavicon';
import { Organization } from 'src/types/auth';

import styles from './OrganizationDropdown.module.scss';

const OrganizationItems = ({
  organizations,
  onClick,
}: {
  organizations: Organization[];
  onClick: (e: any) => void;
}) => {
  const faviconDimension = '16px';
  return (
    <>
      {organizations.map((org) => {
        const { id, name, favicon_image } = org;

        const itemProps = {
          key: `dropdown-item-${id}`,
          'data-organization-id': id,
          'data-testid': `organization-dropdown-item-${id}`,
        };

        return (
          <div key={id}>
            <DropdownItem
              {...itemProps}
              title={name}
              onClick={() => onClick(id)}
              className="d-flex align-items-center"
            >
              {favicon_image ? (
                <OrganizationFavicon
                  organization={org}
                  height={faviconDimension}
                  width={faviconDimension}
                  className="me-2"
                />
              ) : (
                <span
                  style={{ width: faviconDimension }}
                  className="me-2 d-inline-block"
                ></span>
              )}
              <span className="fwmedium">{name}</span>
            </DropdownItem>
          </div>
        );
      })}
    </>
  );
};

interface Props {
  organizations: Organization[];
  onChange: (organizationId: number) => void;
  activeOrganization: Organization | undefined;
  headerTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  headerClassname?: string;
  className?: string;
}

const OrganizationDropdown = ({
  organizations,
  onChange,
  activeOrganization,
  headerTag = 'h1',
  className,
  headerClassname,
}: Props) => {
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
  const handleToggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);

  const handleOrganizationClick = React.useCallback(
    (oid: number) => {
      onChange(oid);
    },
    [onChange]
  );

  // allow for dynamic headers
  const Heading = headerTag as keyof JSX.IntrinsicElements;

  if (organizations.length === 0 || !activeOrganization) {
    return null;
  }

  if (organizations.length === 1) {
    return (
      <div className={cx('d-flex align-items-center', className)}>
        <OrganizationFavicon
          organization={activeOrganization}
          // decorative, to be ignored by screen readers
          alt=""
          className="me-2"
        />
        <Heading
          data-testid="organization-label"
          className={cx('mb-0', headerClassname)}
        >
          {activeOrganization.name}
        </Heading>
      </div>
    );
  }

  return (
    <Dropdown
      isOpen={dropdownIsOpen}
      toggle={handleToggleDropdown}
      data-testid="organization-dropdown"
      className={cx(styles['organization-dropdown-container'], className)}
      direction="down"
    >
      <DropdownToggle
        color="''" /* disable bootstrap coloring */
        className={styles.dropdownToggle}
        data-testid="organization-dropdown-toggle"
      >
        <div className="d-flex align-items-center">
          <OrganizationFavicon
            organization={activeOrganization}
            // decorative, to be ignored by screen readers
            alt=""
            className="me-2"
          />
          <Heading className={cx('mb-0', headerClassname)}>
            <span className="me-2">{activeOrganization.name}</span>
            <FontAwesomeIcon icon="caret-down" size="xs" />
          </Heading>
        </div>
      </DropdownToggle>
      <DropdownMenu>
        <OrganizationItems
          organizations={organizations}
          onClick={handleOrganizationClick}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default OrganizationDropdown;
