import { User } from './auth';
import { Conversation, Snippet } from './conversation';

export interface AbstractCode {
  id: number;
  color: string;
  description?: string;
  name: string;
  parent_id?: AbstractCode['id'];
  parentage: AbstractCode['id'][];
  code_type: string;
}

export interface Code extends AbstractCode {
  codebook_id: Codebook['id'];
}

export interface Coding {
  code_id: Code['id'];
  is_suggested?: boolean;
  ai_metadata?: AIMetadata;
}

export type CodeTag = Coding & Code;

export type AIMetadata = {
  client: string;
  model: string;
  reason: string;
};

export interface Catalog {
  id: number;
  title: string;
  description: string;
  organization_id: number;
  codebook_id?: number;
  entries_count?: number;
  total_duration?: number;
  community_id?: number;
}

export interface Codebook {
  id: number;
  title: string;
  description?: string;
}

export interface PagingParameters {
  limit?: number;
  page?: number;
  catalogId: number;
  conversation_ids?: number[];
  highlighter_ids?: number[];
  participant_ids?: number[];
}

export interface Demographic extends AbstractCode {
  catalog_id: Catalog['id'];
}

export interface Participant {
  id: number;
  name: string;
  conversation_id: Conversation['id'];
  demographic_ids: Demographic['id'][];
}

export interface ImportedParticipantRow {
  participant_name: Participant['name'];
  conversation_id: Conversation['id'];
  demographic_name: string; // this is the value of the demographic
  parent_name: Demographic['name']; // this is the name of the demographic
}

//API
export interface NewCatalogResponse {
  catalog_id: Catalog['id'];
  codebook_id: Codebook['id'];
}

export interface CatalogsEntities {
  catalogs: { [key: string]: Catalog };
}

export interface Entry {
  id: number;
  catalog_id: Catalog['id'];
  annotation_id: number;
  audio_end_offset: number;
  audio_start_offset: number;
  audio_fade_in_out?: number;
  audio_url: string;
  code_ids: Code['id'][];
  codings: Coding[];
  content: string;
  conversation_id: number;
  conversation_title: string;
  title: string;
  description: string;
  participant_ids?: string[]; // API is returning string, not number
  primary_participant_id?: number;
  is_internal?: boolean;
  status?: EntryStatus;
  secret: string;
  user_id: User['id'];
  user_name: string;
  snippet_ids: string[];
  suggested_codings: Coding[];
}

export interface VisibilityMap {
  [id: number]: boolean;
}

export enum EntryStatus {
  InProgress = 'in-progress',
  NeedsReview = 'needs-review',
  Reviewed = 'reviewed',
  ToDo = 'to-do',
}

export type VisibilityCategory =
  | 'conversations'
  | 'demographics'
  | 'highlighters'
  | 'internalCodes'
  | 'participants'
  | 'structuralCodes'
  | 'thematicCodes';

export interface VisibilitySetting {
  category: VisibilityCategory;
  id: number;
  visible: boolean | undefined;
}

export type Visibility =
  | 'visible'
  | 'invisible'
  | 'implicitly-visible'
  | 'implicitly-invisible'
  | undefined;

export interface CatalogEntities {
  catalogs: { [key: string]: Catalog };
  codebooks: { [key: string]: Codebook };
  codes: { [key: string]: Code };
  demographics: { [key: string]: Demographic };
}

/**
 * Entities returned by the /catalogs/{id}/entries endpoint
 */
export interface EntriesEntities {
  authors: { [key: string]: User };
  catalog_entries: { [key: string]: Entry };
  conversations: { [key: string]: Conversation };
  participants: { [key: string]: Participant };
  snippets: { [key: string]: Snippet };
}

/**
 * Entities returned by the /catalogs/{id}/conversations endpoint
 */

export interface ConversationsEntities {
  conversations: { [key: string]: Conversation };
}

// AI TAGGING

/**
 * Pending AI tagging requests returned from the /api/ai/coding/catalog/${catalogId} GET endpoint
 */
export interface AITaggingResponse {
  completed_ai_requests: AIRequest[];
  pending_ai_requests: AIRequest[];
  failed_ai_requests: AIRequest[];
  canceled_ai_requests: AIRequest[];
}

export interface CatalogDetailsResponse {
  entities: CatalogEntities;
  filters: CatalogFilters;
}

export interface CatalogFilters {
  authors: FilterData[];
  codes: FilterData[];
  conversations: FilterData[];
  demographics: FilterData[];
  primary_participants: FilterData[];
}

interface FilterData {
  id: number | string;
  name: string;
  count: number; // count of total highlights that match filter value
}

/**
 * Filter Types - Order Impacts UI
 */

export enum FilterJoin {
  'AND',
  'OR',
}

export enum FilterCategory {
  'APPLIED_CODES',
  'AI_CODES',
  'CODES_BY',
  'CONVERSATIONS',
  'HIGHLIGHTED_BY',
  'HIGHLIGHT_STATUS',
  'SPEAKER_DEMOGRAPHICS',
  'SPEAKER_NAME',
  // 'ASSIGNED_TO',
}
export enum FilterHasCondition {
  'HAS_ALL',
  'HAS_ANY',
  'HAS_NONE',
  'IS_EXACT',
  'IS_EMPTY',
  'IS_NOT_EMPTY',
}
export enum FilterIsCondition {
  'IS_ANY',
  'IS_NONE',
  'IS_EMPTY',
  'IS_NOT_EMPTY',
}
export type FilterCondition = FilterHasCondition | FilterIsCondition;

export interface FilterOption extends FilterData {
  color?: string; //only on codes for display purposes
}

export type FilterOptions = { [key in FilterCategory]: FilterOption[] };
export interface AIRequest {
  ai_request_status: AIRequestStatus;
  annotation_id: Entry['annotation_id'];
  highlight_status?: EntryStatus;
  codes?: AICode[];
}

export interface AICode {
  code_id: number;
  code_name: string;
  code_parent_id?: number | null;
  code_parent_name?: string | null;
}

export enum AIRequestStatus {
  pending = 'pending',
  completed = 'completed',
  canceled = 'canceled',
  failed = 'failed',
}
