import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CollectionRoleTooltip = ({ width }: { width: string }) => {
  const [tooltipCollectionRole, setTooltipCollectionRole] =
    React.useState<boolean>(false);
  const { t } = useTranslation();
  //Strings for tooltip
  const MANAGER = React.useMemo(() => t('admin.collection_role_manager'), [t]);
  const MANAGERDESCRIPTION = React.useMemo(
    () => t('admin.collection_role_manager_description'),
    [t]
  );
  const COLLABORATOR = React.useMemo(
    () => t('admin.collection_role_collaborator'),
    [t]
  );
  const COLLABORATORDESCRIPTION = React.useMemo(
    () => t('admin.collection_role_collaborator_description'),
    [t]
  );
  const HIGHLIGHTER = React.useMemo(
    () => t('admin.collection_role_highlighter'),
    [t]
  );
  const HIGHLIGHTERDESCRIPTION = React.useMemo(
    () => t('admin.collection_role_highlighter_description'),
    [t]
  );
  const VIEWER = React.useMemo(() => t('admin.collection_role_viewer'), [t]);
  const VIEWERDESCRIPTION = React.useMemo(
    () => t('admin.collection_role_viewer_description'),
    [t]
  );
  return (
    <>
      <div
        id="collection-role-helper"
        tabIndex={0}
        className="ms-2"
        style={{ width: '40px' }}
        aria-label={`${MANAGER} - ${MANAGERDESCRIPTION}, ${COLLABORATOR} - ${COLLABORATORDESCRIPTION}, 
        ${HIGHLIGHTER} - ${HIGHLIGHTERDESCRIPTION}, ${VIEWER} - ${VIEWERDESCRIPTION}`}
      >
        <FontAwesomeIcon
          icon={['fas', 'question-circle']}
          size="1x"
          color="gray"
        />
      </div>
      <Tooltip
        placement="right-start"
        hideArrow={true}
        isOpen={tooltipCollectionRole}
        innerClassName={'d-flex flex-column bg-white shadow text-dark'}
        style={{ width }}
        target={'collection-role-helper'}
        toggle={() => setTooltipCollectionRole((self) => !self)}
        data-testid={`tooltip-collection-role-helper`}
      >
        <span className={'w-100 text-start fwbold'}>{MANAGER}</span>
        <span className={'w-100 text-start ms-1'}>{MANAGERDESCRIPTION}</span>
        <span className={'w-100 text-start fwbold'}>{COLLABORATOR}</span>
        <span className={'w-100 text-start ms-1'}>
          {COLLABORATORDESCRIPTION}
        </span>
        <span className={'w-100 text-start fwbold'}>{HIGHLIGHTER}</span>
        <span className={'w-100 text-start ms-1'}>
          {HIGHLIGHTERDESCRIPTION}
        </span>
        <span className={'w-100 text-start fwbold'}>{VIEWER}</span>
        <span className={'w-100 text-start ms-1'}>{VIEWERDESCRIPTION}</span>
      </Tooltip>
    </>
  );
};

export default CollectionRoleTooltip;
