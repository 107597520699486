import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';

import styles from './PillDropdown.module.scss';

type PillTheme = 'lightBlue' | 'lightGreen' | 'lightGray';

interface PillDropdownProps {
  dropdownItems: {
    value: string;
    theme?: PillTheme;
    hide?: boolean; // If true, will not show in dropdown
  }[];
  selectedValue: string;
  loading?: boolean;
  onSelect: (value: string) => void;
  disable?: boolean; // disable dropdown
  id?: string;
  testId?: string;
}

const PillDropdown = ({
  dropdownItems,
  selectedValue,
  loading,
  disable,
  id,
  onSelect,
  testId,
}: PillDropdownProps) => {
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState<boolean>(false);

  const selectedItem = React.useMemo(
    () =>
      dropdownItems.filter(
        (item) => item.value.toLowerCase() === selectedValue.toLowerCase()
      )[0],
    [dropdownItems, selectedValue]
  );

  return (
    <Dropdown
      isOpen={dropdownIsOpen}
      toggle={() => setDropdownIsOpen((self) => !self)}
      data-testid={testId}
      direction="down"
      disabled={disable}
    >
      <DropdownToggle
        className={styles.dropdownToggle}
        data-testid="pill-dropdown-toggle"
        id={id}
      >
        <span className={cx(styles.pill)}>{selectedItem.value}</span>
        {!disable &&
          (loading ? (
            <span className={styles.spinner}>
              <LoadingSpinner active />
            </span>
          ) : (
            <FontAwesomeIcon
              icon="caret-down"
              className={styles.caret}
              data-testid="pill-dropdown-caret"
            />
          ))}
      </DropdownToggle>
      <DropdownMenu className="">
        {dropdownItems.map((item, ind) => (
          <React.Fragment key={ind}>
            {!item.hide && (
              <DropdownItem
                className="fwmedium"
                data-testid={`${item.value}-item`}
                onClick={() => onSelect(item.value)}
              >
                {selectedItem.value === item.value && (
                  <FontAwesomeIcon
                    icon={['far', 'check']}
                    data-testid="check-icon"
                    className={styles.checkIcon}
                  />
                )}
                <span>{item.value}</span>
              </DropdownItem>
            )}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default PillDropdown;
