import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeEvery } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { UserTransactions, UserType } from 'src/types/admin';
import { Organization, User } from 'src/types/auth';
import { Collection } from 'src/types/collection';
import { ServerError } from 'src/types/core';
import { Community, CommunityMembersResult } from 'src/types/forum';
import {
  processAddUser,
  processCollectionRoleUpdates,
  processCommunityRoleUpdates,
  processLinkUser,
  processOrganizationRoleUpdates,
  processRemoveUser,
  processUserUpdates,
} from './admin-slice-helpers';

interface AdminState {
  transactions: UserTransactions[];
  users: User[];
  currentUserId: number | undefined;
  userFetchError: ServerError | undefined;
  userType: UserType | undefined;
  organization: Organization | undefined;
  collections: Collection[];
  collectionsFetchError: ServerError | undefined;
  communities: Community[];
  community: Community | undefined;
  communitiesFetchError: ServerError | undefined;
  communityMembers: CommunityMembersResult;
  communityMembersFetchError: ServerError | undefined;
  organizationChangeFetchError: ServerError | undefined;
  usersLoading: boolean;
}

// initial state for reducer
const initialState: AdminState = {
  transactions: [],
  users: [],
  currentUserId: undefined,
  userFetchError: undefined,
  userType: undefined,
  organization: undefined,
  collections: [],
  collectionsFetchError: undefined,
  communities: [],
  community: undefined,
  communitiesFetchError: undefined,
  communityMembers: {
    community_members: [],
    non_community_users: [],
    community_users: [],
  },
  communityMembersFetchError: undefined,
  organizationChangeFetchError: undefined,
  usersLoading: false,
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setOrganization(state, action: PayloadAction<Organization>) {
      state.organization = action.payload;
      state.communityMembers = initialState.communityMembers;
      state.usersLoading = true;
    },
    getOrganizationChangeFailure(state, action: PayloadAction<ServerError>) {
      state.organizationChangeFetchError = action.payload;
      state.usersLoading = false;
    },
    setCommunity(state, action: PayloadAction<Community>) {
      state.community = action.payload;
      state.usersLoading = true;
    },
    getCollectionsSuccess(state, action: PayloadAction<Collection[]>) {
      state.collections = action.payload;
      state.usersLoading = false;
    },
    getCollectionsFailure(state, action: PayloadAction<ServerError>) {
      state.collectionsFetchError = action.payload;
    },
    getCommunitiesSuccess(state, action: PayloadAction<Community[]>) {
      state.communities = action.payload;
      state.community = action.payload[0];
      state.usersLoading = false;
    },
    getCommunitiesFailure(state, action: PayloadAction<ServerError>) {
      state.communitiesFetchError = action.payload;
    },
    getCommunityMembersSuccess(
      state,
      action: PayloadAction<CommunityMembersResult>
    ) {
      state.communityMembers = action.payload;
      state.usersLoading = false;
    },
    getCommunityMembersFailure(state, action: PayloadAction<ServerError>) {
      state.communityMembersFetchError = action.payload;
      state.usersLoading = false;
    },
    getUsers(
      state,
      action: PayloadAction<{ organizationId?: number; userType: UserType }>
    ) {
      state.userType = action.payload.userType;
      state.usersLoading = true;
    },
    getUsersSuccess(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
      state.usersLoading = false;
    },
    getUsersFailure(state, action: PayloadAction<ServerError>) {
      state.userFetchError = action.payload;
      state.usersLoading = false;
    },
    setCurrentUser(state, action: PayloadAction<{ id: number | undefined }>) {
      state.currentUserId = action.payload.id;
    },
    userTransaction: {
      reducer(state, action: PayloadAction<UserTransactions>) {
        // Add transaction to list of transactions
        state.transactions = state.transactions.concat([action.payload]);
      },
      prepare: (userTransaction: UserTransactions) => {
        return { payload: userTransaction };
      },
    },
    userTransactionSuccess(
      state,
      action: PayloadAction<{
        updatedUser: Partial<User>;
        transaction: UserTransactions;
      }>
    ) {
      let newUsers = [...state.users];
      if (action.payload.transaction.type === 'remove') {
        newUsers = newUsers.filter(
          (user) => user.id !== action.payload.updatedUser.id
        );
      } else if (action.payload.transaction.type === 'create') {
        const existingUser = state.users.filter(
          (user: User) => user.id === action.payload.updatedUser.id
        )[0];
        if (!existingUser) {
          // Prevent duiplicates
          newUsers.push(action.payload.updatedUser as User);
        }
        // Must modify transaction to contain the userId after post request
        const newTransactions = [...state.transactions];
        newTransactions.map((transaction) => {
          if (transaction.id === action.payload.transaction.id) {
            const transactionData = {
              ...transaction.data,
              id: action.payload.updatedUser.id as number,
            };
            transaction.data = transactionData;
          }
          return transaction;
        });
        state.transactions = newTransactions;
      } else {
        newUsers = state.users.map((user) => {
          if (user.id === action.payload.updatedUser.id) {
            return Object.assign(user, action.payload.updatedUser);
          }
          return user;
        });
      }
      state.users = newUsers;
    },
    removeUserTransaction(state, action: PayloadAction<UserTransactions>) {
      const newTransactions = state.transactions.filter((transaction) => {
        return transaction.id !== action.payload.id;
      });
      state.transactions = newTransactions;
    },
    userTransactionFailure(
      state,
      action: PayloadAction<{
        error: ServerError;
        transaction: UserTransactions;
      }>
    ) {
      const newTransactions = state.transactions.map((transaction) => {
        if (transaction.id === action.payload.transaction.id) {
          transaction.error = action.payload.error;
        }
        return transaction;
      });
      state.transactions = newTransactions;
    },
  },
});

export const {
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  userTransaction,
  userTransactionSuccess,
  userTransactionFailure,
  removeUserTransaction,
  setOrganization,
  setCommunity,
  getCollectionsSuccess,
  getCollectionsFailure,
  getCommunitiesSuccess,
  getCommunitiesFailure,
  getCommunityMembersSuccess,
  getCommunityMembersFailure,
  getOrganizationChangeFailure,
  setCurrentUser,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export function* sagaGetUsers(action: ReturnType<typeof getUsers>) {
  try {
    const { userType, organizationId } = action.payload;
    let userList = [];
    if (userType === 'members' && organizationId != null) {
      userList = yield call(api.getUsersInOrganization, organizationId);
    }
    // fire the action with successful response
    yield put(getUsersSuccess(userList));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(getUsersFailure(err as ServerError));
  }
}

export function* sagaSetOrganization(
  action: ReturnType<typeof setOrganization>
) {
  try {
    const organization = action.payload;

    // Get the organization users
    const userList: SagaReturnType<typeof api.getUsersInOrganization> =
      yield call(api.getUsersInOrganization, organization.id);

    // Get the collections in the organization
    const collections: SagaReturnType<typeof api.getCollections> = yield call(
      api.getCollections,
      {
        organizationId: organization.id,
      }
    );

    // Get the communities in the organization
    const communities: SagaReturnType<typeof api.getCommunities> = yield call(
      api.getCommunities,
      {
        organizationId: organization.id,
      }
    );

    // Set the default community
    const defaultCommunity = communities.length ? communities[0] : undefined;

    // If user has access to any communities, get the community members in the default community for the organization
    if (defaultCommunity) {
      const communityMembers: SagaReturnType<typeof api.getCommunityMembers> =
        yield call(api.getCommunityMembers, {
          communityId: defaultCommunity.id,
        });
      yield put(getCommunityMembersSuccess(communityMembers));
    }

    // Put the successful data in the state object
    yield put(getCollectionsSuccess(collections));
    yield put(getUsersSuccess(userList));
    yield put(getCommunitiesSuccess(communities));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(getOrganizationChangeFailure(err as ServerError));
  }
}

export function* sagaGetCommunityMembers(
  action: ReturnType<typeof setCommunity>
) {
  try {
    const community = action.payload;
    const communityMembers: SagaReturnType<typeof api.getCommunityMembers> =
      yield call(api.getCommunityMembers, {
        communityId: community.id,
      });
    // fire the action with successful response
    yield put(getCommunityMembersSuccess(communityMembers));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(getCommunityMembersFailure(err as ServerError));
  }
}

export function* sagaUserTransaction(
  action: ReturnType<typeof userTransaction>
) {
  try {
    switch (action.payload.type) {
      case 'create':
        try {
          yield call(processAddUser, action.payload);
        } catch (err) {
          const failure = JSON.parse((err as Error).message);
          if (failure.data.id) {
            // The user already exists
            yield call(processLinkUser, action.payload, failure.data.id);
          } else {
            throw new Error(err as string);
          }
        }
        break;
      case 'update_user_details':
        yield call(processUserUpdates, action.payload);
        break;
      case 'update_user_organization_role':
        yield call(processOrganizationRoleUpdates, action.payload);
        break;
      case 'update_user_community_role':
        yield call(processCommunityRoleUpdates, action.payload);
        break;
      case 'update_user_collection_role':
        yield call(processCollectionRoleUpdates, action.payload);
        break;
      case 'remove':
        yield call(processRemoveUser, action.payload);
        break;
    }
  } catch (err) {
    const error = api.transformAccountsServerError(err as Error);
    // an error occurred, fire the failure action
    yield put(userTransactionFailure({ error, transaction: action.payload }));
  }
}

export function* sagaRemoveUserTransaction(
  action: ReturnType<typeof userTransactionSuccess>
) {
  yield put(removeUserTransaction(action.payload.transaction));
}

export const sagas = [
  takeEvery(setOrganization.type, sagaSetOrganization),
  takeEvery(getUsers.type, sagaGetUsers),
  takeEvery(setCommunity.type, sagaGetCommunityMembers),
  takeEvery(userTransaction.type, sagaUserTransaction),
  takeEvery(userTransactionSuccess.type, sagaRemoveUserTransaction),
];
