import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import color from 'src/assets/_util.scss';
import Button from 'src/components/core/Button/Button';
import { VSpacer } from 'src/components/core/Spacer';
import { HStack } from 'src/components/core/Stack';
import { selectEntry } from 'src/redux/catalog/catalog-slice';
import {
  Entry,
  FilterCategory,
  FilterCondition,
  FilterHasCondition,
  FilterIsCondition,
  FilterJoin,
} from 'src/types/insights';
import HighlightCard from '../Catalog/HighlightCard';
import { useHighlightsPageContext } from '../Catalog/HighlightsPageProvider';
import {
  disableHasSelectionConditions,
  disableIsSelectionConditions,
  filterHasCategories,
  useFilterLabels,
  useFilterOptions,
} from './utils';

interface Filter {
  category: FilterCategory;
  condition: FilterCondition;
  value: (number | string)[]; // corresponds to id of the category
}

const FilterableHighlightGrid = () => {
  const {
    visibleHighlights,
    selectedHighlightIds,
    setSelectedHighlightIds,
    openFilters,
    dispatch,
    t,
  } = useHighlightsPageContext();

  const onClick = useCallback(
    (entry: Entry) => dispatch(selectEntry(entry.id)),
    [dispatch]
  );

  const onChangeCheck = useCallback(
    (checked: boolean, highlight: Entry) => {
      const nowSelectedHighlights = new Set(selectedHighlightIds);
      nowSelectedHighlights[checked ? 'add' : 'delete'](highlight.id);
      setSelectedHighlightIds(nowSelectedHighlights);
    },
    [selectedHighlightIds, setSelectedHighlightIds]
  );
  const [filterJoin, setFilterJoin] = useState<FilterJoin>(FilterJoin.AND);
  const initialFilter: Filter = {
    category: FilterCategory.APPLIED_CODES,
    condition: FilterHasCondition.HAS_ALL,
    value: [],
  };
  const [filters, setFilters] = useState<Filter[]>([{ ...initialFilter }]);

  const addFilter = () => {
    setFilters((filters) => {
      const newFilters = [...filters];
      newFilters.push(initialFilter);
      return newFilters;
    });
  };

  const updateFilter = (ind: number, filter: Filter) => {
    setFilters((filters) => {
      return filters
        .slice(0, ind)
        .concat([filter])
        .concat(filters.slice(ind + 1));
    });
  };

  const deleteFilter = (ind: number) => {
    if (ind === 0 && filters.length === 1) {
      // Reset Filters
      setFilters([{ ...initialFilter }]);
      return;
    }
    setFilters((filters) => filters.filter((_, x) => x !== ind));
  };

  const canDeleteFilters = filters.length > 1 || filters[0].value.length;

  return (
    <Grid
      container
      sx={{
        overflow: 'hidden',
      }}
    >
      <Grid
        item
        xs={6}
        style={{
          border: `1px solid ${color.gray400}`,
          borderRadius: '0 24px 0 0',
          display: openFilters ? 'block' : 'none',
          marginTop: '.5rem',
        }}
        sx={{ height: '100%', overflow: 'auto' }}
      >
        <Container
          sx={{
            width: '100%',
            marginBottom: '100px',
          }}
        >
          <Typography variant="h5" sx={{ padding: '24px 0' }}>
            Highlight Filters
          </Typography>
          <Typography>
            Find the highlights you're looking for by setting custom filters
            below.
          </Typography>
          <Typography>
            For help creating filters, check out this article.
          </Typography>
          <VSpacer />
          <Typography fontWeight={600} paddingTop={'24px'}>
            Showing 54 highlights
          </Typography>
          {filters.map((filter, ind) => (
            <FilterInputRow
              index={ind}
              key={ind}
              filter={filter}
              updateFilter={updateFilter}
              deleteFilter={canDeleteFilters ? deleteFilter : undefined}
              filterJoin={filterJoin}
              setFilterJoin={setFilterJoin}
            />
          ))}
          <Button
            outline
            icon={['far', 'plus']}
            onClick={addFilter}
            style={{ color: color.foraPurple }}
            disabled={filters.length >= 5}
          >
            <Typography>Add Filter</Typography>
          </Button>
        </Container>
      </Grid>
      <Grid
        item
        xs={openFilters ? 6 : 12}
        sx={{ height: '100%', overflow: 'auto' }}
      >
        <Grid
          container
          item
          spacing={3}
          xs={8}
          style={{
            maxWidth: '1280px',
            width: '100%',
            flexBasis: 'unset',
            margin: '0 auto',
            paddingTop: '.5rem',
            overflowY: 'auto',
            boxSizing: 'border-box',
            justifyContent: 'center',
          }}
        >
          {visibleHighlights?.map((entry) => (
            <Grid
              item
              alignItems="stretch"
              lg={openFilters ? 12 : 6}
              key={entry.id}
              sx={{
                padding: '0 24px 24px 24px !important',
                maxWidth: { sm: '650px' },
              }}
            >
              <HighlightCard
                entry={entry}
                key={entry.id}
                onClick={() => onClick(entry)}
                onChangeCheck={onChangeCheck}
                checked={selectedHighlightIds.has(entry.id)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const FilterInputRow = ({
  index,
  filter,
  updateFilter,
  deleteFilter,
  filterJoin,
  setFilterJoin,
}: {
  index: number;
  filter: Filter;
  updateFilter: (ind: number, filter: Filter) => void;
  deleteFilter?: (ind: number) => void;
  filterJoin: FilterJoin;
  setFilterJoin: Dispatch<SetStateAction<FilterJoin>>;
}) => {
  const {
    filterJoinLabel,
    filterCategoryLabel,
    filterHasConditionLabel,
    filterIsConditionLabel,
    filterValueLabel,
  } = useFilterLabels();
  const filterOptions = useFilterOptions()[filter.category];

  const isHasCategory = (category: FilterCategory) =>
    filterHasCategories.includes(parseInt(category.toString()));

  const disableSelection = (condition: FilterCondition) =>
    isHasCategory(filter.category)
      ? disableHasSelectionConditions.includes(parseInt(condition.toString()))
      : disableIsSelectionConditions.includes(parseInt(condition.toString()));

  const getConditionForNewCategory = (
    category: FilterCategory,
    condition: FilterCondition
  ): FilterCondition => {
    if (isHasCategory(filter.category) && !isHasCategory(category)) {
      // Category changing from has category to is category
      switch (parseInt(condition.toString())) {
        case FilterHasCondition.IS_EMPTY:
          return FilterIsCondition.IS_EMPTY;
        case FilterHasCondition.IS_NOT_EMPTY:
          return FilterIsCondition.IS_NOT_EMPTY;
        default:
          return FilterIsCondition.IS_ANY;
      }
    } else if (!isHasCategory(filter.category) && isHasCategory(category)) {
      // category changing from is category to has category
      switch (parseInt(condition.toString())) {
        case FilterIsCondition.IS_EMPTY:
          return FilterHasCondition.IS_EMPTY;
        case FilterIsCondition.IS_NOT_EMPTY:
          return FilterHasCondition.IS_NOT_EMPTY;
        default:
          return FilterHasCondition.HAS_ALL;
      }
    } else {
      // Category has the same condition dropdown
      return condition;
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent<FilterCategory>) => {
    updateFilter(index, {
      condition: getConditionForNewCategory(
        event.target.value as unknown as FilterCategory,
        filter.condition
      ),
      category: event.target.value as unknown as FilterCategory,
      value: [], // value should be reset
    });
  };

  const handleConditionChange = (event: SelectChangeEvent<FilterCondition>) => {
    let value = filter.value;
    const condition = event.target.value as unknown as FilterCondition;
    if (disableSelection(condition)) {
      // If the change disables selection, clear the value field
      value = [];
    }
    updateFilter(index, {
      ...filter,
      condition,
      value,
    });
  };

  const handleValueChange = (event: SelectChangeEvent<(number | string)[]>) => {
    const value = event.target.value as unknown as number[];
    updateFilter(index, {
      ...filter,
      value,
    });
  };

  return (
    <HStack sx={{ width: '100%', margin: '1rem 0' }}>
      {index !== 0 && (
        <FormControl
          size="small"
          sx={{ maxWidth: '85px', flex: 2, margin: '0 .25rem' }}
        >
          <Select
            sx={{
              padding: '0px !important',
              borderRadius: '8px',
            }}
            value={filterJoin}
            disabled={index !== 1}
            onChange={(event) => {
              setFilterJoin(event.target.value as unknown as FilterJoin);
            }}
            IconComponent={ExpandMoreRounded}
            MenuProps={{
              sx: {
                '& .MuiPaper-root': {
                  marginTop: '.5rem',
                  boxShadow: '0px 2px 6px 0px #00000040 !important',
                },
              },
            }}
          >
            {Object.keys(filterJoinLabel).map((key) => {
              return (
                <MenuItem value={key} key={key}>
                  {filterJoinLabel[key as unknown as FilterJoin]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      <FormControl size="small" sx={{ flex: 3, margin: '0 .25rem' }}>
        <Select
          sx={{
            padding: '0px !important',
            borderRadius: '8px',
          }}
          value={filter.category}
          onChange={handleCategoryChange}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                marginTop: '.5rem',
                boxShadow: '0px 2px 6px 0px #00000040 !important',
              },
            },
          }}
        >
          {Object.keys(filterCategoryLabel).map((key) => {
            return (
              <MenuItem value={key} key={key}>
                {filterCategoryLabel[key as unknown as FilterCategory]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl size="small" sx={{ flex: 2, margin: '0 .25rem' }}>
        <Select
          sx={{
            padding: '0px !important',
            borderRadius: '8px',
          }}
          value={filter.condition}
          onChange={handleConditionChange}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            sx: {
              '& .MuiPaper-root': {
                marginTop: '.5rem',
                boxShadow: '0px 2px 6px 0px #00000040 !important',
              },
            },
          }}
        >
          {isHasCategory(filter.category)
            ? Object.keys(filterHasConditionLabel).map((key) => {
                return (
                  <MenuItem value={key} key={key}>
                    {
                      filterHasConditionLabel[
                        key as unknown as FilterHasCondition
                      ]
                    }
                  </MenuItem>
                );
              })
            : Object.keys(filterIsConditionLabel).map((key) => {
                return (
                  <MenuItem value={key} key={key}>
                    {
                      filterIsConditionLabel[
                        key as unknown as FilterIsCondition
                      ]
                    }
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
      {!disableSelection(filter.condition) && (
        <FormControl size="small" sx={{ flex: 4, margin: '0 .25rem' }}>
          <InputLabel
            id="filter-options"
            shrink={false}
            sx={{
              visibility: filter.value.length ? 'hidden' : 'visible',
            }}
          >
            {filterValueLabel[filter.category]}
          </InputLabel>
          <Select
            id="filter-options"
            sx={{
              padding: '0px !important',
              borderRadius: '8px',
            }}
            value={filter.value}
            onChange={handleValueChange}
            multiple
            disabled={disableSelection(filter.condition)}
            IconComponent={ExpandMoreRounded}
            MenuProps={{
              sx: {
                '& .MuiPaper-root': {
                  marginTop: '.5rem',
                  boxShadow: '0px 2px 6px 0px #00000040 !important',
                },
              },
            }}
            renderValue={(selected) =>
              selected
                .map((i) => filterOptions.find((o) => o.id === i)?.name)
                .join(', ')
            }
          >
            {filterOptions.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                <Checkbox checked={filter.value.indexOf(option.id) > -1} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {deleteFilter && (
        <Button
          color="plain"
          size="lg"
          icon={['far', 'trash-can']}
          style={{ margin: '0 .25rem', padding: '0px !important' }}
          onClick={() => {
            deleteFilter(index);
          }}
        />
      )}
    </HStack>
  );
};

export default FilterableHighlightGrid;
