import React from 'react';
import cx from 'classnames';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import CommunityRoleDropdown from 'src/components/AdminPage/OrganizationMembers/PillDropdowns/CommunityRoleDropdown';
import RemoveMemberButton from 'src/components/AdminPage/OrganizationMembers/RemoveMemberButton/RemoveMemberButton';
import BgImage from 'src/components/BgImage/BgImage';
import Button from 'src/components/core/Button/Button';
import { User } from 'src/types/auth';
import { CommunityMember } from 'src/types/forum';
import OrganizationRoleDropdown from '../OrganizationMembers/PillDropdowns/OrganizationRoleDropdown';
import UserStatus from '../OrganizationMembers/UserStatus/UserStatus';
import { TeamPageName } from './MemberListPage';

import styles from './MemberRow.module.scss';

interface MembersRowProps {
  members: User[] | CommunityMember[];
  member: User | CommunityMember;
  isFirst?: boolean;
}

const ROW_HEIGHT = 60;

const MembersRow = ({ members, member, isFirst = false }: MembersRowProps) => {
  const [query] = useQueryParams({
    tab: StringParam,
  });
  const { tab: activeTab } = query;
  // These Refs are needed because the whole row is a button, and we need to position the
  // other buttons in the row on top of the row button.
  const organizationDiv = React.useRef<HTMLDivElement>(null);
  const removeDiv = React.useRef<HTMLDivElement>(null);
  const statusDiv = React.useRef<HTMLDivElement>(null);
  const communityDiv = React.useRef<HTMLDivElement>(null);
  const [removePosition, setRemovePosition] = React.useState<{
    top: number;
    left: number;
  }>();
  const [organizationPosition, setOrganizationPosition] = React.useState<{
    top: number;
    left: number;
  }>();
  const [statusPosition, setStatusPosition] = React.useState<{
    top: number;
    left: number;
  }>();

  React.useLayoutEffect(() => {
    const updatePositions = () => {
      setRemovePosition({
        top: (removeDiv.current?.offsetTop ?? 0) - ROW_HEIGHT / 5,
        left: removeDiv.current?.offsetLeft ?? 0,
      });
      setOrganizationPosition({
        top: (organizationDiv.current?.offsetTop ?? 0) - ROW_HEIGHT / 5 - 8,
        left: organizationDiv.current?.offsetLeft ?? 0,
      });
      setStatusPosition({
        top: (statusDiv.current?.offsetTop ?? 0) - ROW_HEIGHT / 5 - 8,
        left: statusDiv.current?.offsetLeft ?? 0,
      });
    };
    if (organizationDiv && removeDiv && statusDiv && communityDiv) {
      updatePositions();
    }
    window.addEventListener('resize', updatePositions);
    return () => {
      window.removeEventListener('resize', updatePositions);
    };
  }, [organizationDiv, removeDiv, statusDiv, communityDiv, members]);

  const avatarStr = member.email ? member.email[0].toUpperCase() : ' ';
  const memberFullName = `${member.first_name || ''} ${member.last_name || ''}`;
  const [, setQuery] = useQueryParams({
    userId: NumberParam,
    page: StringParam,
  });

  // If the row is the first row in the list, remove the borders
  const rowStyle = isFirst ? 'border-0 p-0' : ' border-top p-0';

  const onPageChage = (userId: number, page: TeamPageName) => {
    setQuery({ userId, page }, 'pushIn');
  };

  return (
    <>
      <Button
        outline
        className={cx(styles.tr, 'w-100 px-0 py-2 shadow-none row', rowStyle)}
        onClick={() => {
          onPageChage(member.id, 'view');
        }}
        data-testid={`team-member-button-${member.id}`}
        style={{ height: `${ROW_HEIGHT} px` }}
      >
        {/* Name Column */}
        <div className="col-4 p-0">
          <div className="shadow-none ps-3 w-100 d-flex justify-content-start">
            {member.profile_image ? (
              <BgImage
                src={member.profile_image}
                className={cx('rounded-circle me-3')}
                width="40px"
                height="40px"
                vignette
              />
            ) : (
              <div
                className={cx(
                  'rounded-circle me-3 d-flex justify-content-center align-items-center',
                  styles.avatar
                )}
              >
                {avatarStr}
              </div>
            )}
            <div className="d-flex flex-column text-start">
              <span className={styles.name}>{memberFullName}</span>
              <span>{member.email}</span>
            </div>
          </div>
        </div>
        {/* OrganizationRole Column */}
        <div className={cx(styles.full, 'col-2 p-0')} ref={organizationDiv} />
        {/* Status Column */}
        <div className={cx(styles.full, 'col-5 p-0')} ref={statusDiv} />
        {/* Remove Column */}
        <div className={cx(styles.full, 'col-1 p-0')} ref={removeDiv} />
      </Button>
      {/* TO-DO: Consolidate Dropdown components into one unified component */}
      {organizationDiv &&
        (activeTab === 'community_members' ? (
          <div style={{ position: 'absolute', ...organizationPosition }}>
            <CommunityRoleDropdown member={member} />
          </div>
        ) : (
          <div style={{ position: 'absolute', ...organizationPosition }}>
            <OrganizationRoleDropdown member={member} />
          </div>
        ))}
      {statusDiv && (
        <div style={{ position: 'absolute', ...statusPosition }}>
          <UserStatus user={member} />
        </div>
      )}
      {removeDiv && (
        <div
          className={`text-center h-full width-full`}
          style={{ position: 'absolute', margin: 'auto', ...removePosition }}
        >
          <RemoveMemberButton
            member={member}
            communityTable={activeTab === 'community_members'}
          />
        </div>
      )}
    </>
  );
};

export default MembersRow;
