import { useMemo } from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash';

import Color from 'src/assets/_util.scss';
import {
  ArrowLeftButton,
  ArrowRightButton,
} from 'src/components/core/Button/ArrowButton';
import { HStack } from 'src/components/core/Stack';
import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import HighlightCard from 'src/components/Insights/Catalog/HighlightCard';
import {
  HighlightsPerPage,
  useHighlightsPageContext,
} from 'src/components/Insights/Catalog/HighlightsPageProvider';
import { selectEntry } from 'src/redux/catalog/catalog-slice';

export function HighlightCardModal() {
  const { selectedEntry } = useCatalogPageContext();
  return !selectedEntry ? null : (
    <>
      <HighlightCard entry={selectedEntry} open />
      <CarouselNavBar />
    </>
  );
}

function CarouselNavBar() {
  const {
    selectedEntry,
    allHighlights: highlights,
    setCurrentPage,
    dispatch,
    t,
  } = useHighlightsPageContext();

  const itemsById = useMemo(
    () =>
      _.keyBy(
        highlights.map((entry, index) => ({ ...entry, index })),
        'id'
      ),
    [highlights]
  );

  const highlightCount = highlights.length;
  const activeIndex = selectedEntry ? itemsById[selectedEntry.id].index : -1;
  const onFirstEntry = activeIndex === 0;
  const onLastEntry = activeIndex === highlightCount - 1;

  const goTo = (index: number) => {
    dispatch(selectEntry(highlights[index].id));
    setCurrentPage(Math.floor(index / HighlightsPerPage + 1));
  };

  return (
    <HStack
      style={{
        background: Color.white,
        borderRadius: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
        zIndex: 1500,
        minWidth: '151px',
        flex: '0 0 auto',
        marginTop: 'auto',
        pointerEvents: 'auto',
      }}
    >
      <ArrowLeftButton
        size={32}
        disabled={onFirstEntry}
        onClick={() => goTo(activeIndex - 1)}
        aria-label={t('highlights.previous')}
      />
      <Typography
        variant="body2"
        style={{ fontWeight: '600', color: Color.gray800 }}
      >
        {activeIndex + 1} / {highlightCount}
      </Typography>
      <ArrowRightButton
        size={32}
        disabled={onLastEntry}
        onClick={() => goTo(activeIndex + 1)}
        aria-label={t('highlights.next')}
      />
    </HStack>
  );
}
