import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';

import authSelectors from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';
import { Permission, Role, User } from 'src/types/auth';
import { FeatureFlags } from 'src/types/core';
import {
  getFlag,
  hasAllPermissions,
  hasAnyPermission,
  hasAnyRole,
  hasPermission,
  hasRole,
  isAtLeastOrAtMostRole,
} from 'src/util/user';

interface BaseProps {
  permission?: Permission;
  allPermissions?: Permission[];
  anyPermission?: Permission[];
  atLeastRole?: Role;
  atMostRole?: Role;
  exactRole?: Role;
  anyRole?: Role[];
  flag?: FeatureFlags;
  /** Pass a user ID to require that it matches the logged in user */
  activeUserId?: string | number;

  /** any: at least one restriction is true, all: all of them must be true */
  type?: 'any' | 'all';

  children?: React.ReactNode;
}

interface StateProps {
  user: User;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
});

/**
 * Ensure that the signed in user has the correct permissions, otherwise
 * render nothing.
 */
class Restrict extends React.Component<Props> {
  render() {
    const {
      permission,
      allPermissions,
      anyPermission,
      activeUserId,
      atLeastRole,
      atMostRole,
      anyRole,
      exactRole,
      flag,
      user,
      children,
      type = 'any',
    } = this.props;

    if (!user || children == null) {
      return null;
    }

    let numRestrictions = 0;
    let numPassed = 0;

    if (permission) {
      numRestrictions += 1;
      if (hasPermission(user, permission)) {
        numPassed += 1;
      }
    }

    if (allPermissions) {
      numRestrictions += 1;
      if (hasAllPermissions(user, allPermissions)) {
        numPassed += 1;
      }
    }

    if (anyPermission) {
      numRestrictions += 1;
      if (hasAnyPermission(user, anyPermission)) {
        numPassed += 1;
      }
    }

    if (activeUserId != null) {
      numRestrictions += 1;
      if (user && user.id === activeUserId) {
        numPassed += 1;
      }
    }

    if (atLeastRole) {
      numRestrictions += 1;
      if (isAtLeastOrAtMostRole(user, atLeastRole, 'atLeast')) {
        numPassed += 1;
      }
    }

    if (atMostRole) {
      numRestrictions += 1;
      if (isAtLeastOrAtMostRole(user, atMostRole, 'atMost')) {
        numPassed += 1;
      }
    }

    if (anyRole) {
      numRestrictions += 1;
      if (hasAnyRole(user, anyRole)) {
        numPassed += 1;
      }
    }

    if (exactRole) {
      numRestrictions += 1;
      if (hasRole(user, exactRole)) {
        numPassed += 1;
      }
    }

    if (flag) {
      numRestrictions += 1;
      if (getFlag(user, flag)) {
        numPassed += 1;
      }
    }

    if (
      (type === 'any' && numPassed === 0) ||
      (type === 'all' && numPassed !== numRestrictions)
    ) {
      return null;
    }

    return children;
  }
}

export default connect(mapStateToProps)(Restrict);
