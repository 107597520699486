import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp, useSelector } from 'react-redux';
import { Container, UncontrolledAlert } from 'reactstrap';
import { TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { StringParam, useQueryParams } from 'use-query-params';

import Layout from 'src/components/Layout/Layout';
import OrganizationDropdown from 'src/components/OrganizationDropdown/OrganizationDropdown';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { setOrganization } from 'src/redux/admin/admin-slice';
import authSelectors from 'src/redux/auth/auth-selectors';
import organizationSelectors from 'src/redux/organizations/organizations-selectors';
import { loadOrganizationsMetadata } from 'src/redux/organizations/organizations-slice';
import { StoreState } from 'src/redux/store';
import { Organization, Permission, User } from 'src/types/auth';
import { OrganizationMetadata } from 'src/types/organization';
import {
  getAdminPageOrgs,
  getCollectionsPermission,
  getDraftConversationPermission,
  getOrganizationMembersPermission,
  hasAnyPermission,
} from 'src/util/user';
import AdminTab from './AdminTab/AdminTab';
import Communities from './Communities/Communities';
import DraftConversations from './DraftConversations/DraftConversations';
import ManageCollections from './ManageCollections/ManageCollections';
import MembersList from './MemberListPage/MemberList';
import MemberListPage from './MemberListPage/MemberListPage';
import AddMember from './OrganizationMembers/AddMember/AddMember';
import ManageTeam from './OrganizationMembers/ManageTeam';
import ViewMember from './OrganizationMembers/ViewMember/ViewMember';

export type TabName =
  | 'manage_collections'
  | 'draft_conversations'
  | 'manage_team'
  | 'organization_members'
  | 'communities'
  | 'community_members';

interface StateProps {
  user: User;
  organizationsMetadata: OrganizationMetadata[] | undefined;
  activeOrganization: Organization | undefined;
}

type Props = StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
  organizationsMetadata: organizationSelectors.getOrganizations(state),
  activeOrganization: adminSelectors.getActiveOrganization(state),
});

export const AdminPage = ({
  user,
  organizationsMetadata,
  activeOrganization,
  dispatch,
}: Props) => {
  const USER_MANAGEMENT_FLAG = user.flags?.user_management_changes;
  const organizationMembers = useSelector(adminSelectors.getUsers);
  const communityMembers = useSelector(adminSelectors.getCommunityMembers);

  const filteredCommunityMembers =
    communityMembers?.filter((communityMember) => {
      return organizationMembers?.find((organizationMember) => {
        return organizationMember.id === communityMember.id;
      });
    }) ?? [];

  const { t } = useTranslation();
  const tabs: TabName[] = USER_MANAGEMENT_FLAG
    ? [
        'draft_conversations',
        'manage_collections',
        'organization_members',
        'communities',
        'community_members',
      ]
    : ['draft_conversations', 'manage_collections', 'manage_team'];

  const orgsWithAdmin = React.useMemo(() => {
    return getAdminPageOrgs(user);
  }, [user]);

  React.useEffect(() => {
    dispatch(loadOrganizationsMetadata());
    if (!activeOrganization && orgsWithAdmin.length) {
      orgsWithAdmin[0] && dispatch(setOrganization(orgsWithAdmin[0]));
    }
  }, [user, dispatch, activeOrganization, orgsWithAdmin]);

  const getTab = (tab: TabName) => {
    const permissions = getAdminPagePermissions(activeOrganization?.id);
    switch (tab as string) {
      case 'draft_conversations':
        return (
          <AdminTab
            tabName="draft_conversations"
            canAccess={hasAnyPermission(user, permissions.draft_conversations)}
          />
        );
      case 'manage_collections':
        return (
          <AdminTab
            tabName="manage_collections"
            canAccess={hasAnyPermission(user, permissions.manage_collections)}
          />
        );
      case 'manage_team':
        return (
          <AdminTab
            tabName="manage_team"
            canAccess={hasAnyPermission(user, permissions.manage_team)}
          />
        );
      case 'organization_members':
        return (
          <AdminTab
            tabName="organization_members"
            tooltipText="organization_members_tooltip"
            canAccess={hasAnyPermission(user, permissions.organization_members)}
          />
        );
      case 'communities':
        return (
          <AdminTab
            tabName="communities"
            canAccess={hasAnyPermission(user, permissions.communities)}
          />
        );
      case 'community_members':
        return (
          <AdminTab
            tabName="community_members"
            canAccess={hasAnyPermission(user, permissions.community_members)}
          />
        );
      default:
        return undefined;
    }
  };

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    page: StringParam,
    userId: StringParam,
  });
  const { tab: activeTab } = query;

  const onTabChange = (tabIndex: number) => {
    // Remove all queryParams other than tab.
    // Change tab queryParam to current tab.
    setQuery(
      { tab: tabs[tabIndex], page: undefined, userId: undefined },
      'pushIn'
    );
  };

  React.useEffect(() => {
    // if no query param on mount, add one
    // this keeps user on collection mgmt tab if they
    // create a collection then gain the `read X Draft Conversations` permission
    if (
      activeTab === undefined ||
      // if we switch orgs, we may end up on a tab we don't have access to
      // so also go back to the first tab
      (tabs.length && tabs.indexOf(activeTab as TabName) === -1)
    ) {
      onTabChange(0);
    }
  });

  const orgMetadata = organizationsMetadata?.find(
    (org) => org.id === activeOrganization?.id
  );
  const showCappedWarning =
    orgMetadata && orgMetadata.num_conversations >= orgMetadata.upload_cap;
  const capWarningBanner = showCappedWarning ? (
    <div className="my-2">
      <UncontrolledAlert color="danger" data-testid="capped-banner">
        <Trans
          i18nKey="conversation_upload.capped"
          /*eslint-disable */
          components={{ 1: <a href="mailto:help@lvn.org" /> }}
          /*eslint-disable */
        />
      </UncontrolledAlert>
    </div>
  ) : undefined;

  const getTabPanelContent = (tab: TabName) => {
    if (activeTab !== tab) {
      return <></>;
    }
    switch (activeTab as TabName) {
      case 'manage_collections':
        return (
          <ManageCollections
            organization={activeOrganization}
            organizationMetadata={orgMetadata}
          />
        );
      case 'draft_conversations':
        return <DraftConversations />;
      case 'manage_team':
        return <ManageTeam />;
      case 'organization_members':
        return (
          <MemberListPage
            defaultScreen={<MembersList members={organizationMembers} />}
            addMemberScreen={<AddMember />}
            viewMemberScreen={<ViewMember />}
          />
        );
      case 'communities':
        return <Communities />;
      case 'community_members':
        return (
          <MemberListPage
            defaultScreen={<MembersList members={filteredCommunityMembers} />}
            addMemberScreen={<AddMember />}
            viewMemberScreen={<ViewMember />}
          />
        );
      default:
        return <></>;
    }
  };

  const handleOrganizationChange = (oid: number) => {
    const newOrg = orgsWithAdmin.find((o) => o?.id === oid);
    if (newOrg) {
      dispatch(setOrganization(newOrg));
    }
  };

  return (
    <Layout title={t('main_nav.account_admin')} className="AdminRoute">
      <Container fluid>
        {capWarningBanner}
        <OrganizationDropdown
          organizations={orgsWithAdmin}
          activeOrganization={activeOrganization}
          onChange={handleOrganizationChange}
          headerTag="h1"
          className="mb-3"
          headerClassname="h2"
        />
        <Tabs
          index={activeTab ? tabs.indexOf(activeTab as TabName) : 0}
          onChange={onTabChange}
        >
          <TabList>{tabs.map((tab) => getTab(tab))}</TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab}>{getTabPanelContent(tab)}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Container>
    </Layout>
  );
};

/**
 * This returns a list of permissions for each tab in the admin page. Check
 * these permissions using a util function in src/util/user
 * @param organization_id - optional parameter to pass organization id specificity to permission
 */
export const getAdminPagePermissions = (
  organization_id?: number
): { [key in TabName]: Permission[] } => ({
  draft_conversations: [getDraftConversationPermission('read')],
  manage_collections: [
    getCollectionsPermission({ operation: 'update' }),
    getCollectionsPermission({ operation: 'create' }),
  ],
  manage_team: [
    getOrganizationMembersPermission('create', organization_id),
    getOrganizationMembersPermission('update', organization_id),
    getOrganizationMembersPermission('delete', organization_id),
  ],
  organization_members: [
    getOrganizationMembersPermission('create', organization_id),
    getOrganizationMembersPermission('update', organization_id),
    getOrganizationMembersPermission('delete', organization_id),
  ],
  communities: [getDraftConversationPermission('read')],
  community_members: [getDraftConversationPermission('read')],
});

export default connect(mapStateToProps)(AdminPage);
