import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Label, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import * as Yup from 'yup';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { UserTransactions } from 'src/types/admin';
import { CRUD } from 'src/types/core';

import styles from './EditMemberDetailsForm.module.scss';

interface EditMemberDetailsFormProps {
  first_name: string;
  last_name: string;
  email: string;
}

interface Props {
  onClose: () => void;
}

const EditMemberDetailsForm = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const TOOLTIP = t('admin.tab_team_member_edit_email_tooltip');
  const [lockTooltip, setLockTooltip] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<UserTransactions>();
  const transactions = useSelector(adminSelectors.getUserTransactions);
  const currentMember = useSelector(adminSelectors.getCurrentUser);
  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  React.useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      )[0];
      if (matchingTransaction) {
        setTransaction(matchingTransaction);
      } else {
        setTransaction(undefined);
        onClose();
      }
    }
  }, [transactions, transaction, setQuery, onClose]);

  const initialValues: EditMemberDetailsFormProps = {
    first_name: currentMember?.first_name ?? '',
    last_name: currentMember?.last_name ?? '',
    email: currentMember?.email ?? '',
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim(),
    last_name: Yup.string().trim(),
    email: Yup.string()
      .trim()
      .matches(/@/, t('register.email_validation_1'))
      .email(t('register.email_validation_2'))
      .required(t('register.email_validation_3')),
  });

  const handleSubmitForm = (values: EditMemberDetailsFormProps) => {
    if (currentMember) {
      // Remove unchanged fields
      const first_name =
        values.first_name === currentMember.first_name
          ? undefined
          : values.first_name;
      const last_name =
        values.last_name === currentMember.last_name
          ? undefined
          : values.last_name;
      const email =
        values.email === currentMember.email ? undefined : values.email;

      const transaction = generateTransaction({
        type: 'update_user_details',
        data: {
          first_name,
          last_name,
          email,
          id: currentMember.id,
        },
        crud: CRUD.put,
      });

      // Order matters - thanks React
      dispatch(userTransaction(transaction));
      setTransaction(transaction);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={validationSchema}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {transaction?.error && (
            <div className={styles.error}>{transaction?.error.message}</div>
          )}
          <div className="d-flex">
            <div className={cx(styles.w600, 'm-auto')}>
              <div className="d-flex">
                <Label for="first_name" className={styles.label} hidden>
                  {t('settings.first_name')}
                </Label>
                <Field
                  name="first_name"
                  id="first_name"
                  type="text"
                  component="input"
                  onChange={props.handleChange}
                  value={props.values.first_name}
                  data-testid="first-name-field"
                  placeholder={t('settings.first_name')}
                  className={cx('form-control', styles.name)}
                />
                <Label for="last_name" className={styles.label} hidden>
                  {t('settings.last_name')}
                </Label>
                <Field
                  name="last_name"
                  id="last_name"
                  type="text"
                  component="input"
                  placeholder={t('settings.last_name')}
                  onChange={props.handleChange}
                  value={props.values.last_name}
                  data-testid="last-name-field"
                  className={cx('form-control', styles.name)}
                />
              </div>
              <div className="position-relative pe-2">
                <Label for="email" className={styles.label} hidden>
                  {t('settings.email')}
                </Label>
                <Field
                  name="email"
                  id="email"
                  type="text"
                  placeholder={t('settings.email')}
                  component="input"
                  onChange={props.handleChange}
                  value={props.values.email}
                  data-testid="email-field"
                  className={cx('form-control', styles.email)}
                  disabled={!!currentMember?.last_login}
                />
                {!!currentMember?.last_login && (
                  <div className={styles.lock}>
                    <FontAwesomeIcon
                      icon={['fas', 'lock']}
                      size="lg"
                      tabIndex={0}
                      id={`email-lock-${currentMember.id}`}
                      data-testid={`email-lock-${currentMember.id}`}
                      className={styles.icon}
                      aria-label={TOOLTIP}
                    />
                    <Tooltip
                      placement="bottom"
                      hideArrow={true}
                      isOpen={lockTooltip}
                      innerClassName={cx(styles.tooltip)}
                      target={`email-lock-${currentMember.id}`}
                      toggle={() => setLockTooltip((self) => !self)}
                      data-testid={`tooltip-email-lock-${currentMember.id}`}
                    >
                      {TOOLTIP}
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-column">
              <Button
                color="primary"
                type="submit"
                disabled={!props.dirty || !!transaction}
                data-testid="view-member-submit"
                className={cx(styles.submit, 'mt-0')}
              >
                {transaction && !transaction.error ? (
                  <LoadingSpinner active size="md" />
                ) : (
                  t('common.save')
                )}
              </Button>
              <Button
                color="secondary"
                data-testid="view-member-cancel"
                className={cx(styles.submit)}
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default EditMemberDetailsForm;
