import Fuse from 'fuse.js';
import _ from 'lodash';

import { sortCodes } from 'src/components/Insights/utils/code';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation, Snippet } from 'src/types/conversation';
import {
  AbstractCode,
  Catalog,
  CatalogFilters,
  Code,
  CodeTag,
  Demographic,
  Entry,
  Participant,
} from 'src/types/insights';
import { selectors as catalogFilterSelectors } from '../catalog-filters/catalog-filters-selectors';
import { DisplayMode, LoadingMode } from './catalog-slice';

export const selectors = {
  getAuthors: (state: StoreState): User[] => {
    return Object.values(state.catalog.authors);
  },
  getAuthorForEntry: (state: StoreState, entry: Entry): User => {
    return state.catalog.authors[entry.user_id];
  },
  getCatalog: (state: StoreState): Catalog => {
    return state.catalog.catalog;
  },
  getCodebook: (state: StoreState) => {
    return state.catalog.codebook;
  },
  getCodeCount: (state: StoreState, code: AbstractCode): number => {
    if (code.code_type === 'demographic') {
      return state.catalog.demographicCounts[`${code.id}`] || 0;
    } else {
      return state.catalog.codeCounts[`${code.id}`] || 0;
    }
  },
  getCodeTagsForEntry: (state: StoreState, entry: Entry): CodeTag[] => {
    return (
      entry.codings?.map((coding) => {
        const code = state.catalog.codes[coding.code_id];
        return {
          ...code,
          ...coding,
        };
      }) ?? []
    );
  },
  getConversations: (state: StoreState): Conversation[] => {
    return Object.values(state.catalog.conversations);
  },
  getConversationByEntry: (state: StoreState, entry: Entry) => {
    return Object.values(state.catalog.conversations).find((conversation) => {
      return conversation.id === entry.conversation_id;
    });
  },
  getDemographics: (state: StoreState): Demographic[] => {
    return sortCodes(Object.values(state.catalog.demographics));
  },
  getDemographicsForEntry: (state: StoreState, entry: Entry): Demographic[] => {
    const codes: Demographic[] = [];
    (entry.participant_ids || []).map((id) => {
      const participant: Participant | undefined =
        state.catalog.participants[`${id}`];
      if (participant !== undefined) {
        participant.demographic_ids.map((id) => {
          const code: Demographic | undefined =
            state.catalog.demographics[`${id}`];
          if (code !== undefined) {
            codes.push(code);
          }
        });
      }
    });
    return codes;
  },
  getDemographicsForParticipants: (
    state: StoreState,
    participants: Participant[]
  ) => {
    const demographics: { [key: Participant['id']]: Demographic[] } = {};
    participants.map((participant) => {
      demographics[participant.id] = participant.demographic_ids
        .map((id) => {
          return state.catalog.demographics[id];
        })
        .filter((demographic) => demographic !== undefined);
    });
    return demographics;
  },
  getEntries: (state: StoreState): Entry[] => {
    const [codesToShow, codesToHide] =
      catalogFilterSelectors.getCodeVisibility(state);
    const [conversationsToShow, conversationsToHide] =
      catalogFilterSelectors.getConversationVisibility(state);
    const [demographicsToShow, demographicsToHide] =
      catalogFilterSelectors.getDemographicVisibility(state);
    const [highlightersToShow, highlightersToHide] =
      catalogFilterSelectors.getHighlighterVisibility(state);
    const [participantsToShow] =
      catalogFilterSelectors.getParticipantVisibility(state);
    const transcriptSearch = catalogFilterSelectors.getTranscriptSearch(state);
    let entries = Object.values(state.catalog.entries);
    if (transcriptSearch.length) {
      const fuse = new Fuse(entries, {
        ignoreLocation: true,
        includeScore: true,
        keys: ['content'],
        threshold: 0.3,
      });
      entries = fuse.search(transcriptSearch).map((result) => {
        return result.item;
      });
    }
    return entries.filter((entry) => {
      // conversations
      if (
        conversationsToShow.length &&
        conversationsToShow.indexOf(entry.conversation_id) === -1
      ) {
        return false;
      } else if (
        conversationsToHide.length &&
        conversationsToHide.indexOf(entry.conversation_id) !== -1
      ) {
        return false;
      }
      // highlighters
      if (
        highlightersToShow.length &&
        highlightersToShow.indexOf(entry.user_id) === -1
      ) {
        return false;
      } else if (
        highlightersToHide.length &&
        highlightersToHide.indexOf(entry.user_id) !== -1
      ) {
        return false;
      }
      /**
       * Participants
       *
       * If the intersection of an entry's participant_ids array and the participantsToShow array is empty, that means the highlight doesn't include any
       * of the desired participants, and should be filtered out.
       */
      if (
        participantsToShow.length &&
        _.intersection(
          entry.participant_ids,
          participantsToShow.map((participantId) => participantId.toString())
        ).length === 0
      ) {
        return false;
      }

      // codes
      if (
        codesToShow.length &&
        !entry.code_ids.filter((id) => codesToShow.indexOf(id) !== -1).length
      ) {
        return false;
      } else if (
        codesToHide.length &&
        entry.code_ids.filter((id) => codesToHide.indexOf(id) !== -1).length
      ) {
        return false;
      }

      // demographics
      const demographicIds: Set<Demographic['id']> = new Set();
      (entry.participant_ids || []).map((id) => {
        const participant = state.catalog.participants[`${id}`];
        if (participant !== undefined) {
          participant.demographic_ids.map((id) => demographicIds.add(id));
        }
      });
      if (
        demographicsToShow.length &&
        !Array.from(demographicIds).filter(
          (id) => demographicsToShow.indexOf(id) !== -1
        ).length
      ) {
        return false;
      } else if (
        demographicsToHide.length &&
        Array.from(demographicIds).filter(
          (id) => demographicsToHide.indexOf(id) !== -1
        ).length
      ) {
        return false;
      }

      return true;
    });
  },
  /**
   * Returns all entries, unfiltered. Used to get accurate counts for the Conversations page
   * @param state StoreState
   */
  getAllEntries: (state: StoreState): Entry[] => {
    return Object.values(state.catalog.entries);
  },
  getDisplayMode: (state: StoreState): DisplayMode => {
    return state.catalog.displayMode;
  },
  getLoadingMode: (state: StoreState): LoadingMode => {
    return state.catalog.loadingMode;
  },
  getError: (state: StoreState): Error | undefined => {
    return state.catalog.error;
  },
  getInternalCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'internal'
      )
    );
  },
  getParticipants: (state: StoreState): Participant[] => {
    return Object.values(state.catalog.participants);
  },
  getParticipantsForConversation: (
    state: StoreState,
    conversation: Conversation
  ): Participant[] => {
    return Object.values(state.catalog.participants).filter((participant) => {
      return participant.conversation_id === conversation.id;
    });
  },
  getStructuralCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'structural'
      )
    );
  },
  getThematicCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'thematic'
      )
    );
  },
  getSelectedConversation: (state: StoreState): Conversation | undefined => {
    return state.catalog.conversations[
      `${state.catalog.selectedConversationId}`
    ];
  },
  getSelectedEntry: (state: StoreState): Entry | undefined => {
    return state.catalog.entries[`${state.catalog.selectedEntryId}`];
  },
  getSelectedParticipant: (state: StoreState): Participant | undefined => {
    return state.catalog.participants[`${state.catalog.selectedParticipantId}`];
  },
  isLoading: (state: StoreState): boolean => {
    return state.catalog.isLoading;
  },
  getHighlightSnippets: (
    state: StoreState,
    snippetIds: string[]
  ): Snippet[] => {
    const allSnippets = state.catalog.snippets;
    if (Object.keys(allSnippets).length === 0) {
      // Return with an empty list
      return [];
    }
    return snippetIds
      .map((id) => {
        const snippet = allSnippets[id];
        if (snippet) {
          return snippet;
        } else {
          return { id: -1 } as Snippet;
        }
      })
      .filter((s) => s.id !== -1);
  },
  getFilters: (state: StoreState): CatalogFilters => {
    return state.catalog.filters;
  },
};

export default selectors;
