import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import SearchInput from 'src/components/SearchInput/SearchInput';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { setCommunity } from 'src/redux/admin/admin-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Community, CommunityMember } from 'src/types/forum';
import { sortUsers } from 'src/util/user';
import CommunityDropdown from '../CommunityDropdown/CommunityDropdown';
import { TeamPageName } from './MemberListPage';
import MemberTable from './MemberTable';

import styles from './MemberList.module.scss';

const matchMember = (query: string, member: User | CommunityMember) => {
  // Join the queries to match on (note, first last position is important)
  return `${member.first_name} ${member.last_name} ${member.email}`
    .toLowerCase() // Make case insensitive
    .includes(query.toLowerCase());
};

interface MembersListProps {
  members: User[] | CommunityMember[];
}

interface StateProps {
  communities: Community[];
  activeCommunity: Community | undefined;
}

type Props = StateProps & MembersListProps;

const mapStateToProps = (state: StoreState): StateProps => ({
  communities: adminSelectors.getCommunities(state),
  activeCommunity: adminSelectors.getActiveCommunity(state),
});

const MembersList = ({ members, communities, activeCommunity }: Props) => {
  const dispatch = useDispatch();
  const error = useSelector(adminSelectors.getUsersError);
  const isLoadingUsers = useSelector(adminSelectors.isLoadingUsers);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState<string>('');

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    page: StringParam,
    userId: NumberParam,
  });
  const { tab: activeTab } = query;

  const onPageChange = (page: TeamPageName) => {
    setQuery({ page, userId: undefined }, 'pushIn');
  };

  const isLoading = isLoadingUsers && !error;

  const filteredMembers = searchQuery
    ? members.filter((m) => matchMember(searchQuery, m))
    : members;

  const sortedMembers = [...filteredMembers].sort(sortUsers);

  const handleCommunityChange = (oid: number) => {
    const newOrg = communities.find((c) => c?.id === oid);
    if (newOrg) {
      dispatch(setCommunity(newOrg));
    }
  };

  return (
    <div className={styles.fixedWidth}>
      {isLoading ? (
        <div className="w-100 text-center">
          <LoadingSpinner thick size="lg" active theme="dark" />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between mb-4 flex-wrap">
            <div className="d-flex flex-column">
              {activeTab === 'community_members' && (
                <CommunityDropdown
                  communities={communities}
                  activeCommunity={activeCommunity}
                  onChange={handleCommunityChange}
                  headerTag="h1"
                  className="mb-3"
                  headerClassname="h2"
                />
              )}
              <div className="d-flex flex-row">
                <span>
                  {activeTab === 'organization_members'
                    ? t('admin.tab_team_list_helper_1')
                    : t('admin.tab_community_list_helper_1')}
                </span>
              </div>
              <span>
                {activeTab === 'organization_members'
                  ? t('admin.tab_team_list_helper_2', {
                      count: members.length,
                    })
                  : t('admin.tab_community_list_helper_2', {
                      count: members.length,
                    })}
              </span>
            </div>
            <div className="flex-row d-flex flex-wrap">
              <div style={{ width: '350px', margin: 'auto' }}>
                <SearchInput
                  initialSearchQuery={searchQuery || ''}
                  shape="round"
                  placeholder={t('common.search')}
                  size="lg"
                  onSearch={setSearchQuery}
                  onClear={() => setSearchQuery('')}
                />
              </div>
              <div style={{ margin: 'auto' }}>
                <Button
                  type="submit"
                  color="primary"
                  className="ms-4"
                  style={{ width: '200px', height: '40px' }}
                  onClick={() => onPageChange('add')}
                  data-testid={`team-add-button`}
                >
                  {t('common.add')}
                </Button>
              </div>
            </div>
          </div>
          {searchQuery && (
            <Trans
              i18nKey={
                sortedMembers.length > 0
                  ? 'search.results_description'
                  : 'search.results_description_none'
              }
              values={{
                query: searchQuery,
              }}
              components={{
                1: <b />,
              }}
            />
          )}
          {sortedMembers.length > 0 && <MemberTable members={sortedMembers} />}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(MembersList);
