import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import cx from 'classnames';

import BgImage from 'src/components/BgImage/BgImage';
import { User } from 'src/types/auth';
import CommunityMemberToggle from '../../CommunityMemberToggle/CommunityMemberToggle';
import OrganizationRoleDropdown from '../../PillDropdowns/OrganizationRoleDropdown';
import EditMemberDetailsForm from './EditMemberDetailsForm';

import styles from './MemberDetails.module.scss';

interface MemberDetailsProps {
  member?: User;
}

const MemberDetails = ({ member = {} as User }: MemberDetailsProps) => {
  const { t } = useTranslation();
  const [editing, setEditing] = React.useState<boolean>(false);
  const avatarStr = member.email ? member.email[0].toUpperCase() : ' ';
  const memberFullName = `${member.first_name || ''} ${member.last_name || ''}`;

  return (
    <div className="d-flex mt-4">
      {member.profile_image ? (
        <BgImage
          src={member.profile_image}
          className={cx('rounded-circle me-3')}
          width="90px"
          height="90px"
          vignette
        />
      ) : (
        <div
          className={cx(
            'rounded-circle me-3 d-flex justify-content-center align-items-center',
            styles.avatar
          )}
        >
          {avatarStr}
        </div>
      )}
      {editing ? (
        <EditMemberDetailsForm onClose={() => setEditing(false)} />
      ) : (
        <div className="d-flex flex-column text-start">
          <span className={styles.name}>{memberFullName}</span>
          <OrganizationRoleDropdown member={member} />
          <div className={cx('d-flex flex-row', styles.toggle)}>
            Mobile Community Member
            <CommunityMemberToggle member={member} />
          </div>
          <a href={`mailto: ${member.email}`} className={styles.email}>
            {member.email}
          </a>
          <Button
            className={cx(styles.editButton, 'shadow-none')}
            onClick={() => setEditing(true)}
            data-testid={'team-member-edit-button'}
          >
            {t('common.edit')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default MemberDetails;
