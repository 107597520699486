import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import catalogSelectors from 'src/redux/catalog/catalog-selectors';
import {
  FilterCategory,
  FilterHasCondition,
  FilterIsCondition,
  FilterJoin,
  FilterOption,
  FilterOptions,
} from 'src/types/insights';
import { StatusItems } from '../Catalog/status';

export const useFilterLabels = () => {
  const { t } = useTranslation();

  const filterJoinLabel: Record<FilterJoin, string> = useMemo(
    () => ({
      [FilterJoin.AND]: 'And',
      [FilterJoin.OR]: 'Or',
    }),
    []
  );

  const filterCategoryLabel: Record<FilterCategory, string> = useMemo(
    () => ({
      [FilterCategory.APPLIED_CODES]: 'Applied code',
      [FilterCategory.AI_CODES]: 'AI-suggested code',
      [FilterCategory.CODES_BY]: 'Code applied by',
      [FilterCategory.CONVERSATIONS]: 'Conversation',
      [FilterCategory.HIGHLIGHTED_BY]: 'Highlighted by',
      [FilterCategory.HIGHLIGHT_STATUS]: 'Highlight status',
      [FilterCategory.SPEAKER_NAME]: 'Speaker name',
      [FilterCategory.SPEAKER_DEMOGRAPHICS]: 'Speaker demographic',
      // [FilterType.ASSIGNED_TO]: 'Assigned to',
    }),
    []
  );

  const filterHasConditionLabel: Record<FilterHasCondition, string> = useMemo(
    () => ({
      [FilterHasCondition.HAS_ALL]: 'Has all of',
      [FilterHasCondition.HAS_ANY]: 'Has any of',
      [FilterHasCondition.HAS_NONE]: 'Has none of',
      [FilterHasCondition.IS_EXACT]: 'Is exactly',
      [FilterHasCondition.IS_EMPTY]: 'Is empty',
      [FilterHasCondition.IS_NOT_EMPTY]: 'Is not empty',
    }),
    []
  );

  const filterIsConditionLabel: Record<FilterIsCondition, string> = useMemo(
    () => ({
      [FilterIsCondition.IS_ANY]: 'Is any of',
      [FilterIsCondition.IS_NONE]: 'Is none of',
      [FilterIsCondition.IS_EMPTY]: 'Is empty',
      [FilterIsCondition.IS_NOT_EMPTY]: 'Is not empty',
    }),
    []
  );

  const filterValueLabel: Record<FilterCategory, string> = useMemo(
    () => ({
      [FilterCategory.APPLIED_CODES]: 'Select Code',
      [FilterCategory.AI_CODES]: 'Select Code',
      [FilterCategory.CONVERSATIONS]: 'Select Conversation',
      [FilterCategory.CODES_BY]: 'Select Sensemakers',
      [FilterCategory.HIGHLIGHT_STATUS]: 'Select Status',
      [FilterCategory.SPEAKER_NAME]: 'Select Speaker',
      [FilterCategory.SPEAKER_DEMOGRAPHICS]: 'Select Demographics',
      [FilterCategory.HIGHLIGHTED_BY]: 'Select Highlighter',
    }),
    []
  );

  return useMemo(
    () => ({
      filterJoinLabel,
      filterCategoryLabel,
      filterHasConditionLabel,
      filterIsConditionLabel,
      filterValueLabel,
    }),
    [
      filterHasConditionLabel,
      filterJoinLabel,
      filterCategoryLabel,
      filterIsConditionLabel,
      filterValueLabel,
    ]
  );
};

export const disableHasSelectionConditions = [
  FilterHasCondition.IS_EMPTY,
  FilterHasCondition.IS_NOT_EMPTY,
];
export const disableIsSelectionConditions = [
  FilterIsCondition.IS_EMPTY,
  FilterIsCondition.IS_NOT_EMPTY,
];

export const filterHasCategories = [
  FilterCategory.AI_CODES,
  FilterCategory.APPLIED_CODES,
  FilterCategory.CODES_BY,
  FilterCategory.SPEAKER_DEMOGRAPHICS,
];

export const useFilterOptions = (): FilterOptions => {
  const { t } = useTranslation();
  const filters = useSelector(catalogSelectors.getFilters);
  const appliedCodes: FilterOption[] = filters.codes;
  const demographics: FilterOption[] = filters.demographics;
  const aiCodes: FilterOption[] = filters.codes;
  const conversations: FilterOption[] = useSelector(
    catalogSelectors.getConversations
  ).map((c) => ({ id: c.id, name: c.title, count: 0 }));
  const highlightStatus: FilterOption[] = StatusItems.map((item) => ({
    id: item.value,
    name: t(item.label),
    count: 0,
  }));
  const speakers: FilterOption[] = useSelector(
    catalogSelectors.getParticipants
  ).map((c) => ({ id: c.id, name: c.name, count: 0 }));
  const highlighters: FilterOption[] = useSelector(
    catalogSelectors.getAuthors
  ).map((c) => ({ id: c.id, name: c.first_name, count: 0 }));
  return useMemo(
    () => ({
      [FilterCategory.APPLIED_CODES]: appliedCodes,
      [FilterCategory.AI_CODES]: aiCodes,
      [FilterCategory.CONVERSATIONS]: conversations,
      [FilterCategory.CODES_BY]: [],
      [FilterCategory.HIGHLIGHT_STATUS]: highlightStatus,
      [FilterCategory.SPEAKER_NAME]: speakers,
      [FilterCategory.SPEAKER_DEMOGRAPHICS]: demographics,
      [FilterCategory.HIGHLIGHTED_BY]: highlighters,
      // [FilterType.ASSIGNED_TO]: [],
    }),
    [
      aiCodes,
      appliedCodes,
      conversations,
      highlightStatus,
      highlighters,
      speakers,
      demographics,
    ]
  );
};
