import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import color from 'src/assets/_util.scss';
import { Conversation } from 'src/types/conversation';
import { LinkButton } from '../Common/Button';
import CodeChip from '../Common/CodeChip';
import { useConversationsPageContext } from './ConversationsPageProvider';

interface Props {
  conversation: Conversation;
  onClick?: () => void;
  open?: boolean;
}

const ConversationItem: React.FunctionComponent<Props> = ({
  conversation,
  onClick,
  open,
}) => {
  const {
    catalog,
    allEntries,
    conversationParticipants,
    selectedParticipant,
    participantDemographics,
    onSelectConversationParticipant,
    t,
  } = useConversationsPageContext();
  const filteredEntries = allEntries.filter(
    (entry) => entry.conversation_id === conversation.id
  );
  const history = useHistory();
  const openConversation = () => {
    history.push(`/conversation/${conversation.id}`);
  };

  return (
    <Card
      data-testid="ConversationCard"
      elevation={open ? 0 : 2}
      key={conversation.id}
      sx={{
        backgroundColor: color.gray100,
        borderRadius: '24px',
        border: `1px solid ${color.gray300}`,
        cursor: open ? '' : 'pointer',
        position: 'relative',
        transition: 'all 200ms ease-out',
        overflow: 'auto',
        width: '100%',
        marginTop: open ? 'auto' : '',
        pointerEvents: 'auto',
        boxShadow: 'none !important',
        ':hover': {
          boxShadow: open ? '' : '0 0 12px rgba(0, 0, 0, 0.06)',
          transform: open ? '' : 'scale(1.008) translateY(-1px)',
          borderColor: open ? '' : color.gray500,
        },
      }}
      onClick={onClick}
    >
      <CardHeader
        sx={{ pb: 0 }}
        title={
          <Typography
            data-testid="conversation_date"
            sx={{ color: 'gray', fontWeight: 500, m: 0 }}
            variant="subtitle2"
          >
            {conversation.start_time
              ? new Date(conversation.start_time).toLocaleDateString()
              : ''}
          </Typography>
        }
      />
      <CardContent>
        <Typography data-testid="group_title" variant="subtitle1">
          {catalog.community_id ? (
            <Trans
              i18nKey={'insights.conversation_card_forum'}
              values={{
                forumTitle: conversation.forum_name,
              }}
            />
          ) : (
            <Trans
              i18nKey={'insights.conversation_card_collection'}
              values={{
                collectionTitle: conversation.collection_title,
              }}
            />
          )}
        </Typography>
        <Typography data-testid="conversation_title" variant="subtitle1">
          <Trans
            i18nKey={'insights.conversation_card_conversation'}
            values={{
              conversationTitle: conversation.title,
            }}
          />
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 8,
          }}
        >
          <Typography
            data-testid="number_highlights"
            sx={{ color: 'gray' }}
            variant="subtitle2"
          >
            <Trans
              i18nKey={'insights.conversation_card_highlights'}
              values={{
                count: filteredEntries.length,
              }}
            />
          </Typography>
          <Typography
            data-testid="conversation_id"
            sx={{ color: 'gray' }}
            variant="subtitle2"
          >
            #{conversation.id}
          </Typography>
        </div>
        {open && conversationParticipants.length > 0 && (
          <Paper sx={{ mt: 2 }} variant="outlined">
            <Box maxHeight="50vh" sx={{ overflow: 'scroll' }}>
              <List disablePadding>
                {conversationParticipants.map((participant, index) => {
                  const demographics =
                    participantDemographics[participant.id] || [];
                  return (
                    <ListItemButton
                      data-testid="ParticipantOption"
                      divider={index < conversationParticipants.length - 1}
                      key={participant.id}
                      onClick={(event) => {
                        event.stopPropagation();
                        onSelectConversationParticipant(participant.id);
                      }}
                      selected={
                        selectedParticipant &&
                        participant.id === selectedParticipant.id
                      }
                    >
                      <ListItemText
                        primary={participant.name}
                        secondary={
                          <Grid container spacing={1}>
                            {demographics.map((demographic) => (
                              <Grid item key={demographic.id} xs="auto">
                                <CodeChip code={demographic} />
                              </Grid>
                            ))}
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          </Paper>
        )}
      </CardContent>
      {!catalog.community_id && open && (
        <CardActions
          disableSpacing
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingBottom: '24px',
          }}
        >
          <LinkButton
            data-testid="ViewConversationLink"
            onClick={openConversation}
          >
            {t('insights.view_conversation')}
          </LinkButton>
        </CardActions>
      )}
    </Card>
  );
};

export default ConversationItem;
