import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import adminSelectors from 'src/redux/admin/admin-selectors';
import { StoreState } from 'src/redux/store';
import { Collection } from 'src/types/collection';
import CollectionRoleDropdown from '../../PillDropdowns/CollectionRoleDropdown';
import UploadConversationsToggle from './UploadConversationsToggle';

import styles from './MemberCollectionRow.module.scss';

interface MemberCollectionRowProps {
  collection: Collection;
}

const MemberCollectionRow = ({ collection }: MemberCollectionRowProps) => {
  const currentMember = useSelector(adminSelectors.getCurrentUser);
  const isAdminOrOwner = useSelector((state) =>
    adminSelectors.isAdminOrOwner(state as StoreState)
  );
  const roles = isAdminOrOwner
    ? []
    : currentMember?.roles.filter(
        (role) => role.collection_id === collection.id
      );
  return (
    <div
      className={cx(
        { [styles.noRole]: !roles?.length && !isAdminOrOwner },
        'row',
        styles.row
      )}
    >
      <div className={cx('col-4 m-auto')}>
        <Link className={cx(styles.title)} to={`/?c=${collection.id}`}>
          {collection.title}
        </Link>
      </div>
      <div className={cx('col-4 m-auto')}>
        <CollectionRoleDropdown collection={collection} />
      </div>
      <div className={cx('col-4 m-auto')}>
        <UploadConversationsToggle collection={collection} />
      </div>
    </div>
  );
};

export default MemberCollectionRow;
