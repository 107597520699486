import { ScaleOrdinal } from 'd3';
import { Moment } from 'moment-timezone';

import { User } from './auth';
import {
  Conversation,
  Host,
  NormalizedTopicSnippets,
  Paging,
  Snippet,
} from './conversation';

export interface ServerError {
  status: string;
  message: string;
  attribute?: string;
}

export interface Option {
  label: string;
  value: string;
}

export interface NormalizedEntities {
  order: {
    conversations: Array<number>;
    annotations: Array<number>;
    snippets: Array<number>;
    hosts: Array<number>;
    topics: Array<number>;
  };
  entities: {
    conversations: { [key: number]: Conversation };
    annotations: { [key: number]: any };
    snippets: { [key: number]: any };
    hosts: { [key: number]: any };
    topics: { [key: number]: any };
  };
  paging: Paging;
}

export interface NormalizedConversationEntities extends NormalizedEntities {
  filters?: ConversationsFilterOptions;
}

export interface NormalizedHighlightEntities extends NormalizedEntities {
  filters?: HighlightsFilterOptions;
}

// response after editing a transcript
export interface NormalizedSnippetEntities {
  order: { snippets: number[] };
  entities: { snippets: { [key: number]: Snippet } };
  version: number;
}

export interface NormalizedTopicSnippetEntities {
  order: {
    hosts: number[];
    topic_snippets: number[];
  };
  entities: {
    topic_snippets: NormalizedTopicSnippets;
    hosts: { [key: number]: Host };
  };
  paging: Paging;
  filters: TopicSnippetsFilterOptions;
}

export interface FilterOption {
  name: string;
  count?: number;
  id?: number;
  code?: string;
  level?: string; // for privacy level
  term_group?: string[]; // for lemmatized keywords
}

// special type for Related Topics because they only have id, not names
// which we stitch back together in the component
interface RelatedTopicOption {
  id: number;
  count: number;
}

export interface LanguageOption {
  title: string;
  code: string;
}

export interface TopicSnippetsFilterOptions {
  keywords: FilterOption[];
  related_topics: RelatedTopicOption[];
}

export interface ConversationsFilterOptions {
  collections: FilterOption[];
  forums: FilterOption[];
  languages: FilterOption[];
  organizations: FilterOption[];
  privacy_levels: FilterOption[];
}

export interface HighlightsFilterOptions {
  collections: FilterOption[];
  forums: FilterOption[];
  tags: FilterOption[];
  privacy_levels: FilterOption[];
}

export enum FeatureFlags {
  ai_tagging = 'ai_tagging',
  user_management_changes = 'user_management_changes',
  highlight_filtering = 'highlight_filtering',
}

export interface BootstrapData {
  version: string;
  user: User;
  terms_effective_date: Moment | string | null;
  salesforce_url: string;
  languages: LanguageOption[];
  feature_flags?: { [key in FeatureFlags]: boolean };
}

declare global {
  interface Window {
    BOOTSTRAP_DATA: BootstrapData;
  }
}

// used by the topics color scale
export type ColorScale = ScaleOrdinal<string, any>;

export interface LegalContent {
  content: string;
}

export type LngLat = [number, number];

export enum CRUD {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}
