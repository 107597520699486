import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import Button from 'src/components/core/Button/Button';
import OrganizationRoleTooltip from '../Tooltips/OrganizationRoleTooltip';
import AddMemberForm from './AddMemberForm/AddMemberForm';

const AddMember = () => {
  const { t } = useTranslation();

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });
  return (
    <div>
      <Button
        color={'link'}
        onClick={() =>
          setQuery({ userId: undefined, page: undefined }, 'pushIn')
        }
        icon={['far', 'arrow-left']}
        content={t('admin.go_to_team')}
      />
      <div className="d-flex flex-row mb-4">
        <span>{t('admin.tab_team_list_helper_1')}</span>
        <OrganizationRoleTooltip width="300px" />
      </div>
      <AddMemberForm />
    </div>
  );
};

export default AddMember;
